import { appLocalStorage } from '@/app/shared/services';
import { apiBaseUrl } from '@/environment/environment';
import axios from 'axios';
import { Notification } from 'element-ui';

const config = {
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const httpClient = axios.create(config);

const authInterceptor = (config) => {
  const authToken = appLocalStorage.getItem('x-jwt-token');
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
};

const loggerInterceptor = (config) => config;

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      appLocalStorage.removeItem('x-jwt-token');
      appLocalStorage.removeItem('isLoggedIn');
      window.location.href = `/login?message=login`;
    }
    if (error.response) {
      const { data } = error.response;
      Notification({
        title: `Ошибка`,
        message: data.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error);
  }
);

export { httpClient };
