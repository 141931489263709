import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchMeals = (options = {}) => {
  const url = `${apiConstants.meals}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeMeal = (payload) => {
  const url = apiConstants.meals;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateMeal = (id, payload) => {
  const url = `${apiConstants.meals}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteMeal = (id) => {
  const url = `${apiConstants.meals}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchMeals, storeMeal, updateMeal, deleteMeal };
