import { httpClient } from '@/app/shared/services';
import parseQueryParams from '@/utils/parseQueryParams';

const url = `/ingredients`;

export default {
  async FETCH_INGREDIENTS({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_INGREDIENTS', response.data);
    });
  },
  async STORE_INGREDIENT({ commit, getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const ingredients = getters.INGREDIENTS;
      ingredients.data.unshift(response.data.data);
      commit('SET_INGREDIENTS', ingredients);
    });
  },
  async UPDATE_INGREDIENT({ dispatch }, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
    await dispatch('FETCH_INGREDIENTS');
  },
  async DELETE_INGREDIENT({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const ingredients = getters.INGREDIENTS;
      ingredients.data = ingredients.data.filter((ingredient) => ingredient.id !== id);
      commit('SET_INGREDIENTS', ingredients);
    });
  },
  FETCH_INGREDIENT_CATEGORIES({ commit }, options) {
    httpClient.get(`/ingredient/categories${parseQueryParams(options)}`).then((response) => {
      commit('SET_INGREDIENT_CATEGORIES', response.data);
    });
  },
};
