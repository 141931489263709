import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/meals/shared/config';

const mutationTypes = reflectKeys(
  [
    'MEALS_SUCCESS',
    'MEALS_REQUEST',
    'MEALS_ERROR',
    'MEALS_RESET',
    'MEALS_SELECT',
    'MEALS_SELECT_RESET',
  ],
  namespacedPrefix
);

const { MEALS_SUCCESS, MEALS_REQUEST, MEALS_ERROR, MEALS_RESET, MEALS_SELECT, MEALS_SELECT_RESET } =
  mutationTypes;

export { MEALS_SUCCESS, MEALS_REQUEST, MEALS_ERROR, MEALS_RESET, MEALS_SELECT, MEALS_SELECT_RESET };
