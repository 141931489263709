import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/semifisheds/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_SEMIFINISHEDS',
    'FETCH_SEMIFINISHED',
    'STORE_SEMIFINISHED',
    'UPDATE_SEMIFINISHED',
    'DELETE_SEMIFINISHED',
    'SELECT_SEMIFINISHED',
    'SELECT_SEMIFINISHED_RESET',
    'FETCH_SEMIFINISHED_INGREDIENTS',
    'STORE_SEMIFINISHED_INGREDIENT',
    'UPDATE_SEMIFINISHED_INGREDIENT',
    'DELETE_SEMIFINISHED_INGREDIENT',
  ],
  namespacedPrefix
);

const {
  FETCH_SEMIFINISHED,
  FETCH_SEMIFINISHEDS,
  STORE_SEMIFINISHED,
  UPDATE_SEMIFINISHED,
  DELETE_SEMIFINISHED,
  SELECT_SEMIFINISHED,
  SELECT_SEMIFINISHED_RESET,
  FETCH_SEMIFINISHED_INGREDIENTS,
  STORE_SEMIFINISHED_INGREDIENT,
  UPDATE_SEMIFINISHED_INGREDIENT,
  DELETE_SEMIFINISHED_INGREDIENT,
} = actionsTypes;

export {
  FETCH_SEMIFINISHED,
  FETCH_SEMIFINISHEDS,
  STORE_SEMIFINISHED,
  UPDATE_SEMIFINISHED,
  DELETE_SEMIFINISHED,
  SELECT_SEMIFINISHED,
  SELECT_SEMIFINISHED_RESET,
  FETCH_SEMIFINISHED_INGREDIENTS,
  STORE_SEMIFINISHED_INGREDIENT,
  UPDATE_SEMIFINISHED_INGREDIENT,
  DELETE_SEMIFINISHED_INGREDIENT,
};
