<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { AppEmptyLayout, AppMainLayout } from '@/app/shared/layouts';

export default {
  name: 'App',
  components: {
    AppEmptyLayout,
    AppMainLayout,
  },
  computed: {
    layout() {
      return `app-${this.$route.meta.layout || 'empty'}-layout`;
    },
  },
};
</script>

<style lang="scss"></style>
