const cyclesRoutes = [
  {
    path: 'cycles',
    name: 'Cycles',
    meta: {
      layout: 'main',
      title: 'Циклы',
      roles: ['super-admin', 'master-admin', 'administrator', 'manager'],
    },
    component: () => import('./cycles.vue'),
  },
  {
    path: 'cycle/:id',
    name: 'Cycle',
    meta: {
      layout: 'main',
      exclude: true,
      title: 'Цикл',
      roles: ['super-admin', 'master-admin', 'administrator', 'manager'],
    },
    component: () => import('./cycle/cycle.vue'),
  },
];

export default cyclesRoutes;
