import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchCategories = (options = {}) => {
  const url = `${apiConstants.category}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeCategory = (payload) => {
  const url = apiConstants.category;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateCategory = (id, payload) => {
  const url = `${apiConstants.category}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteCategory = (id) => {
  const url = `${apiConstants.category}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchCategories, storeCategory, updateCategory, deleteCategory };
