import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/semifisheds/shared/config';

const mutationTypes = reflectKeys(
  [
    'SEMIFINISHEDS_SUCCESS',
    'SEMIFINISHEDS_REQUEST',
    'SEMIFINISHEDS_ERROR',
    'SEMIFINISHEDS_RESET',
    'SEMIFINISHEDS_SELECT',
    'SEMIFINISHEDS_SELECT_RESET',
    'SEMIFINISHED_SELECT_REQUEST',
    'SEMIFINISHED_INGREDIENTS_SUCCESS',
    'SEMIFINISHED_INGREDIENTS_RESET',
  ],
  namespacedPrefix
);

const {
  SEMIFINISHEDS_SUCCESS,
  SEMIFINISHEDS_REQUEST,
  SEMIFINISHEDS_ERROR,
  SEMIFINISHEDS_RESET,
  SEMIFINISHEDS_SELECT,
  SEMIFINISHEDS_SELECT_RESET,
  SEMIFINISHED_SELECT_REQUEST,
  SEMIFINISHED_INGREDIENTS_SUCCESS,
  SEMIFINISHED_INGREDIENTS_RESET,
} = mutationTypes;

export {
  SEMIFINISHEDS_SUCCESS,
  SEMIFINISHEDS_REQUEST,
  SEMIFINISHEDS_ERROR,
  SEMIFINISHEDS_RESET,
  SEMIFINISHEDS_SELECT,
  SEMIFINISHEDS_SELECT_RESET,
  SEMIFINISHED_SELECT_REQUEST,
  SEMIFINISHED_INGREDIENTS_SUCCESS,
  SEMIFINISHED_INGREDIENTS_RESET,
};
