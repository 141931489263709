import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.UNITS_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.UNITS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.UNITS_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },
  [mutationTypes.UNITS_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.UNITS_SELECT](state, payload) {
    Object.assign(state, { selected: payload });
  },
  [mutationTypes.UNITS_SELECT_RESET](state) {
    Object.assign(state, { selected: { title: '' } });
  },
};
