import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchEmployees = (options = {}) => {
  const url = `${apiConstants.employees}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeEmployee = (payload) => {
  const url = apiConstants.employees;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateEmployee = (id, payload) => {
  const url = `${apiConstants.employees}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteEmployee = (id) => {
  const url = `${apiConstants.employees}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchEmployees, storeEmployee, updateEmployee, deleteEmployee };
