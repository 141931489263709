const clientTypeRoutes = [
  {
    path: 'types',
    name: 'Types',
    meta: {
      layout: 'main',
      title: 'Типы клиентов',
      roles: ['administrator'],
    },
    component: () => import('./client-type-list.vue'),
  },
];

export default clientTypeRoutes;
