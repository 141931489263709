import { httpClient } from '@/app/shared/services';
import moment from 'moment';
import * as actionsTypes from '@/app/manager/shared/state/types/action_types';

const today = moment(Date.now()).format('YYYY-MM-DD');
const tommorow = moment(Date.now()).add(1, 'days').format('YYYY-MM-DD');
const tommorow1 = moment(Date.now()).add(2, 'days').format('YYYY-MM-DD');
const url = `/tasks`;

export default {
  async FETCH_TASKS({ commit }) {
    await httpClient.get(`${url}`).then((response) => {
      if (response.data.data.length) {
        commit('SET_TASKS', response.data.data);
      } else {
        commit('SET_TASKS', [{ date: today }, { date: tommorow }, { date: tommorow1 }]);
      }
    });
  },
  async FETCH_TASK({ commit }, id) {
    await httpClient.get(`${url}/${id}`).then((response) => {
      commit('SET_TASK', response.data.data);
    });
  },
  async STORE_TASK({ commit, getters, dispatch }, data) {
    await httpClient.post(url, data).then((response) => {
      const taskIndex = getters.TASKS.findIndex((task) => task.id === data.id);
      const tasks = getters.TASKS;
      const respData = response.data;
      tasks[taskIndex] = { ...tasks[taskIndex], respData };
      commit('SET_TASKS', tasks);
      dispatch(actionsTypes.FETCH_TASKS, data.options);
    });
  },
  async UPDATE_TASK({ dispatch }, data) {
    await httpClient.put(`${url}/${data.id}`, data);
    dispatch(actionsTypes.FETCH_TASKS, data.options);
  },
  async DELETE_TASK({ dispatch }, payload) {
    await httpClient.delete(`${url}/${payload.id}`);
    dispatch(actionsTypes.FETCH_TASKS, payload.options);
  },
  RESET_TASK({ commit }) {
    commit('SET_TASK', {});
  },
};
