import { httpClient } from '@/app/shared/services';

const url = `/excluded`;

export default {
  async FETCH_EXCLUDED({ commit, getters }) {
    const clientId = getters.CLIENT.id;
    await httpClient.get(`/clients/${clientId}/excluded`).then((response) => {
      commit('SET_EXCLUDED', response.data.data);
    });
  },
  async STORE_EXCLUDED({ dispatch, commit, getters }, data) {
    const clientId = getters.CLIENT.id;
    await httpClient.post(`/clients/${clientId}/excluded`, data).then((response) => {
      const excluded = getters.EXCLUDED;
      excluded.push(response.data.data);
      commit('SET_EXCLUDED', excluded);
    });
    dispatch('FETCH_CLIENT', clientId);
  },
  async UPDATE_EXCLUDED({ dispatch, commit, getters }, data) {
    const clientId = getters.CLIENT.id;
    await httpClient.put(`/clients/excluded/${data.id}`, data).then((response) => {
      const excluded = getters.EXCLUDED;
      excluded.push(response.data.data);
      commit('SET_EXCLUDED', excluded);
    });
    dispatch('FETCH_CLIENT', clientId);
  },
  async DELETE_EXCLUDED({ dispatch, commit, getters }, id) {
    const clientId = getters.CLIENT.id;
    await httpClient.delete(`${url}/${id}`).then(() => {
      commit(
        'SET_EXCLUDED',
        getters.ADDRESS.filter((item) => item.id !== id)
      );
    });
    dispatch('FETCH_CLIENT', clientId);
  },
};
