const kitchenRoutes = [
  {
    path: '/kitchen',
    name: 'kitchen',
    meta: {
      layout: 'main',
      title: 'Кухня',
      icon: 'el-icon-dish',
      isSuperAdmin: false,
      roles: ['super-admin', 'admin', 'administrator', 'master-admin', 'manager'],
    },
    component: () => import('@/views/kitchens/kitchen/Kitchens'),
  },
];

export default kitchenRoutes;
