import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/ingredients/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_INGREDIENTS',
    'FETCH_INGREDIENT_CATEGORY',
    'STORE_INGREDIENT',
    'UPDATE_INGREDIENT',
    'DELETE_INGREDIENT',
    'SELECT_INGREDIENT',
    'SELECT_INGREDIENT_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_INGREDIENT_CATEGORY,
  FETCH_INGREDIENTS,
  STORE_INGREDIENT,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  SELECT_INGREDIENT,
  SELECT_INGREDIENT_RESET,
} = actionsTypes;

export {
  FETCH_INGREDIENT_CATEGORY,
  FETCH_INGREDIENTS,
  STORE_INGREDIENT,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  SELECT_INGREDIENT,
  SELECT_INGREDIENT_RESET,
};
