const clientsRoutes = [
  {
    path: '/clients',
    name: 'Clients',
    meta: {
      layout: 'main',
      auth: true,
      title: 'Клиенты',
      icon: 'el-icon-user-solid',
      roles: ['super-admin', 'manager', 'admin', 'administrator', 'master-admin'],
    },
    component: () => import('./clients.vue'),
  },
  {
    path: '/client/:id',
    name: 'Client',
    meta: {
      layout: 'main',
      title: 'Клиент',
      exclude: true,
    },
    component: () => import('@/views/clients-base/Client'),
    children: [
      {
        path: 'info',
        name: 'info',
        meta: {
          layout: 'main',
          title: 'Информация',
        },
        component: () => import('@/views/clients-base/client/Info'),
      },
      {
        path: 'transactions',
        name: 'transactions',
        meta: {
          layout: 'main',
          title: 'История баланса',
        },
        component: () => import('./client/transactions/transactions.vue'),
      },
      {
        path: 'messages',
        name: 'messages',
        meta: {
          layout: 'main',
          title: 'Сообщения',
        },
        component: () => import('@/views/clients-base/client/Messages'),
      },
      {
        path: 'order',
        name: 'order',
        meta: {
          layout: 'main',
          title: 'Заказ',
        },
        component: () => import('./client/client-orders/client-orders.vue'),
      },
      {
        path: 'history',
        name: 'history',
        meta: {
          layout: 'main',
          title: 'История заказов',
        },
        component: () => import('./client/history/history.vue'),
      },
      {
        path: 'interactions',
        name: 'interactions',
        meta: {
          layout: 'main',
          title: 'История взаимодействий',
        },
        component: () => import('@/views/clients-base/client/Interactions'),
      },
      {
        path: 'tasks',
        name: 'tasks',
        meta: {
          layout: 'main',
          title: 'Задачи',
        },
        component: () => import('./client/client-tasks/client-tasks.vue'),
      },
    ],
  },
];

export default clientsRoutes;
