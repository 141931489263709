import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/branches/shared/config';

const mutationTypes = reflectKeys(
  [
    'BRANCHES_SUCCESS',
    'BRANCHES_REQUEST',
    'BRANCHES_ERROR',
    'BRANCHES_RESET',
    'BRANCHES_SELECT',
    'BRANCHES_SELECT_RESET',
  ],
  namespacedPrefix
);

const {
  BRANCHES_SUCCESS,
  BRANCHES_REQUEST,
  BRANCHES_ERROR,
  BRANCHES_RESET,
  BRANCHES_SELECT,
  BRANCHES_SELECT_RESET,
} = mutationTypes;

export {
  BRANCHES_SUCCESS,
  BRANCHES_REQUEST,
  BRANCHES_ERROR,
  BRANCHES_RESET,
  BRANCHES_SELECT,
  BRANCHES_SELECT_RESET,
};
