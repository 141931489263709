import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/meals/shared/config';

const actionsTypes = reflectKeys(
  ['FETCH_MEALS', 'STORE_MEAL', 'UPDATE_MEAL', 'DELETE_MEAL', 'SELECT_MEAL', 'SELECT_MEAL_RESET'],
  namespacedPrefix
);

const { FETCH_MEALS, STORE_MEAL, UPDATE_MEAL, DELETE_MEAL, SELECT_MEAL, SELECT_MEAL_RESET } =
  actionsTypes;

export { FETCH_MEALS, STORE_MEAL, UPDATE_MEAL, DELETE_MEAL, SELECT_MEAL, SELECT_MEAL_RESET };
