const mealsRoutes = [
  {
    path: 'meals',
    name: 'Meals',
    meta: {
      layout: 'main',
      title: 'Категории приема пищи',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./meals.vue'),
  },
];

export default mealsRoutes;
