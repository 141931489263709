import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/units/shared/config';

const actionsTypes = reflectKeys(
  ['FETCH_UNITS', 'STORE_UNIT', 'UPDATE_UNIT', 'DELETE_UNIT', 'SELECT_UNIT', 'SELECT_UNIT_RESET'],
  namespacedPrefix
);

const { FETCH_UNITS, STORE_UNIT, UPDATE_UNIT, DELETE_UNIT, SELECT_UNIT, SELECT_UNIT_RESET } =
  actionsTypes;

export { FETCH_UNITS, STORE_UNIT, UPDATE_UNIT, DELETE_UNIT, SELECT_UNIT, SELECT_UNIT_RESET };
