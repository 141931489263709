const ingredientsRoutes = [
  {
    path: 'ingredients',
    name: 'Ingredients',
    meta: {
      layout: 'main',
      title: 'Ингредиенты',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./ingredients.vue'),
  },
  {
    path: 'ingredients/:id',
    name: 'Ingredient',
    meta: {
      layout: 'main',
      title: 'Ингредиент',
      roles: ['super-admin', 'master-admin'],
      exclude: true,
    },
    component: () => import('./ingredient/ingredient.vue'),
  },
];

export default ingredientsRoutes;
