const storageRoutes = [
  {
    path: '/storage',
    name: 'StorageIndex',
    meta: {
      icon: 'el-icon-menu',
      title: 'Склад',
      isSuperAdmin: true,
      layout: 'main',
      group: true,
      auth: true,
      menu: true,
      roles: ['administrator', 'admin'],
    },
    component: () => import('@/views/storage/Index'),
    children: [
      {
        path: 'index',
        name: 'storage',
        meta: {
          layout: 'main',
          title: 'Склад',
          roles: ['administrator', 'admin'],
        },
        component: () => import('./storage.vue'),
      },
      {
        path: 'delivery',
        name: 'storage-delivery',
        meta: {
          layout: 'main',
          title: 'Поставки',
          roles: ['administrator', 'admin'],
        },
        component: () => import('./delivery/delivery-list.vue'),
      },
      {
        path: 'remains',
        name: 'storage-remains',
        meta: {
          layout: 'main',
          title: 'Остатки',
          roles: ['administrator', 'admin'],
        },
        component: () => import('./remains/remains-list.vue'),
      },
      {
        path: 'providers',
        name: 'storage-providers',
        meta: {
          layout: 'main',
          title: 'Поставщики',
          roles: ['administrator', 'admin'],
        },
        component: () => import('./providers/providers-list.vue'),
      },
      {
        path: 'write-off',
        name: 'storage-write-off',
        meta: {
          layout: 'main',
          title: 'Списания',
          roles: ['administrator', 'admin'],
        },
        component: () => import('./write-off/write-off-list.vue'),
      },
    ],
  },
];

export default storageRoutes;
