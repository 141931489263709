import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/branches/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_BRANCHES',
    'STORE_BRANCH',
    'UPDATE_BRANCH',
    'DELETE_BRANCH',
    'SELECT_BRANCH',
    'SELECT_BRANCH_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_BRANCHES,
  STORE_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  SELECT_BRANCH,
  SELECT_BRANCH_RESET,
} = actionsTypes;

export {
  FETCH_BRANCHES,
  STORE_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,
  SELECT_BRANCH,
  SELECT_BRANCH_RESET,
};
