import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';
import { SET_ORDERS, SET_ORDER } from './mutations_types';

const url = `/orders`;

export default {
  async FETCH_ORDERS({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit(SET_ORDERS, response.data.data);
    });
  },
  FETCH_ORDER_BY_DATE({ commit }, options) {
    httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit(SET_ORDER, response.data.data);
    });
  },
  STORE_ORDER(_, payload) {
    httpClient.post(url, payload);
  },
  // async UPDATE_CLIENT({ commit, getters }, payload) {
  //   let client = getters.CLIENT;
  //   await httpClient.put(`${url}/${client.id}`, payload)
  //     .then((response) => {
  //       const { data } = response.data;
  //       client = { ...client, ...data };
  //       commit('SET_CLIENT', client);
  //     });
  // },
  DELETE_ORDER(_, id) {
    httpClient.delete(`${url}/${id}`);
  },
};
