import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: {
    dishes: [],
    dish: {},
  },
  getters,
  mutations,
  actions,
};
