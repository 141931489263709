import { httpClient, parseQueryParam } from '@/app/shared/services';
import { apiConstants } from '../config';

const fetchGuides = (options = {}) => {
  const url = `${apiConstants.guide}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchGuide = (id, options = {}) => {
  const url = `${apiConstants.guide}/${id}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeGuide = (payload) => {
  const url = apiConstants.guide;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateGuide = (id, payload) => {
  const url = `${apiConstants.guide}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteGuide = (id) => {
  const url = `${apiConstants.guide}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

const calculateCostPrice = () => {
  const url = '/cost/calculation';
  return httpClient.get(url).then((res) => res.data);
};

export { fetchGuides, fetchGuide, storeGuide, updateGuide, deleteGuide, calculateCostPrice };
