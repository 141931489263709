import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/storage/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_STORAGE',
    'STORE_STORAGE',
    'UPDATE_STORAGE',
    'DELETE_STORAGE',
    'SELECT_STORAGE',
    'SELECT_STORAGE_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_STORAGE,
  STORE_STORAGE,
  UPDATE_STORAGE,
  DELETE_STORAGE,
  SELECT_STORAGE,
  SELECT_STORAGE_RESET,
} = actionsTypes;

export {
  FETCH_STORAGE,
  STORE_STORAGE,
  UPDATE_STORAGE,
  DELETE_STORAGE,
  SELECT_STORAGE,
  SELECT_STORAGE_RESET,
};
