import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: {
    kitchens: [],
    selected: [],
    delivery: [],
    selectedDelivery: [],
  },
  getters,
  mutations,
  actions,
};
