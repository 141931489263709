import { httpClient } from '@/app/shared/services';

import { apiConstants } from '../config';

const login = (authCred) => {
  const url = apiConstants.auth;
  return httpClient.post(url, authCred).then((res) => res.data);
};

const fetchMe = () => {
  const url = apiConstants.me;
  return httpClient.get(url).then((res) => res.data);
};

export { login, fetchMe };
