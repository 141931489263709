export default {
  tasks: [],
  task: {
    description: null,
    date: null,
    time: null,
    client_id: null,
    user_id: null,
    type_id: null,
  },
};
