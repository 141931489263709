import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';

import { fetchTasks, updateTask } from '../services/manager';
import { fetchTypes } from '../services/task-types';

export default {
  async [actionsTypes.FETCH_TYPES]({ commit }) {
    const result = await fetchTypes().catch((e) => commit(mutationTypes.TASKS_ERROR, e.response));
    if (result) {
      commit(mutationTypes.TYPES_SUCCESS, result);
    }
  },
  async [actionsTypes.FETCH_TASKS]({ commit }, options = {}) {
    commit(mutationTypes.TASKS_REQUEST);
    const result = await fetchTasks(options).catch((e) =>
      commit(mutationTypes.TASKS_ERROR, e.response)
    );
    if (result) {
      commit(mutationTypes.TASKS_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.UPDATE_TASK]({ commit }, payload) {
    await updateTask(payload.id, payload).catch((e) =>
      commit(mutationTypes.TASKS_ERROR, e.response)
    );
  },
  [actionsTypes.SELECT_TASK]({ commit }, payload) {
    commit(mutationTypes.SELECT_TASK, payload);
  },
};
