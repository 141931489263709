import { httpClient } from '@/app/shared/services';
import parseQueryParams from '@/utils/parseQueryParams';

const url = `/dishes`;

export default {
  async FETCH_DISHES({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_DISHES', response.data);
    });
  },
  async FETCH_DISH({ commit }, id) {
    await httpClient.get(`${url}/${id}`).then((response) => {
      commit('SET_DISH', response.data.data);
    });
  },
  async STORE_DISH(_, data) {
    await httpClient.post(url, data);
  },
  async UPDATE_DISH({ commit, getters }, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload).then((response) => {
      const { data } = response.data;
      const dishes = getters.DISHES;
      const dishIndex = dishes.data.findIndex((dish) => dish.id === data.id);
      dishes.data[dishIndex] = { ...dishes.data[dishIndex], data };
      commit('SET_DISHES', dishes);
    });
  },
  async DELETE_DISH({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const dishes = getters.DISHES;
      dishes.data = dishes.data.filter((dish) => dish.id !== id);
      commit('SET_DISHES', dishes);
    });
  },
  async STORE_DISH_INGREDIENT({ commit, getters }, payload) {
    const dish = getters.DISH;
    await httpClient.post(`${url}/${dish.id}/ingredient`, payload).then((response) => {
      dish.ingredients.unshift(response.data.data);
      commit('SET_DISHES', dish);
    });
  },
  async UPDATE_DISH_INGREDIENT({ getters }, payload) {
    const dishId = getters.DISH;
    await httpClient.put(`${url}/${dishId.id}/ingredient/${payload.id}`);
  },
  async DELETE_DISH_INGREDIENT({ commit, getters }, id) {
    const dish = getters.DISH;
    await httpClient.delete(`${url}/${dish.id}/ingredient/${id}`).then(() => {
      const dish = getters.DISH;
      dish.ingredients = dish.ingredients.filter((ingredient) => ingredient.id !== id);
      commit('SET_DISH', dish);
    });
  },
};
