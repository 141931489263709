import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.SEMIFINISHEDS_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.SEMIFINISHEDS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.SEMIFINISHEDS_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      error: payload || true,
    });
  },
  [mutationTypes.SEMIFINISHED_INGREDIENTS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, semisIngredients: payload });
  },
  [mutationTypes.SEMIFINISHED_INGREDIENTS_RESET](state) {
    Object.assign(state, { semisIngredients: null });
  },
  [mutationTypes.SEMIFINISHEDS_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.SEMIFINISHED_SELECT_REQUEST](state) {
    Object.assign(state, { selectLoading: true, error: null });
  },
  [mutationTypes.SEMIFINISHEDS_SELECT](state, payload) {
    Object.assign(state, { selectLoading: false, selected: payload });
  },
  [mutationTypes.SEMIFINISHEDS_SELECT_RESET](state) {
    Object.assign(state, { selected: { title: '' } });
  },
};
