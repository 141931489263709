import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: {
    orders: [],
    order: null,
  },
  getters,
  mutations,
  actions,
};
