import * as mutationTypes from './types/mutation_types';

export default {
  [mutationTypes.TASKS_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },

  [mutationTypes.TASKS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },

  [mutationTypes.TASKS_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },

  [mutationTypes.TYPES_SUCCESS](state, payload) {
    Object.assign(state, { types: payload });
  },

  [mutationTypes.SELECT_TASK](state, payload) {
    Object.assign(state, { task: payload });
  },
};
