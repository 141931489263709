import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/dishes/shared/config';

const mutationTypes = reflectKeys(
  [
    'DISHES_SUCCESS',
    'DISHES_REQUEST',
    'DISHES_ERROR',
    'DISHES_RESET',
    'DISHES_SELECT',
    'DISHES_SELECT_RESET',
    'DISH_INGREDIENTS_SUCCESS',
    'DISH_INGREDIENTS_RESET',
  ],
  namespacedPrefix
);

const {
  DISHES_SUCCESS,
  DISHES_REQUEST,
  DISHES_ERROR,
  DISHES_RESET,
  DISHES_SELECT,
  DISHES_SELECT_RESET,
  DISH_INGREDIENTS_SUCCESS,
  DISH_INGREDIENTS_RESET,
} = mutationTypes;

export {
  DISHES_SUCCESS,
  DISHES_REQUEST,
  DISHES_ERROR,
  DISHES_RESET,
  DISHES_SELECT,
  DISHES_SELECT_RESET,
  DISH_INGREDIENTS_SUCCESS,
  DISH_INGREDIENTS_RESET,
};
