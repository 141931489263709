import { appLocalStorage } from '@/app/shared/services';
import {
  calculateCostPrice,
  deleteGuide,
  fetchGuide,
  fetchGuides,
  storeGuide,
  updateGuide,
} from '../services/guide';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';
import * as mutationTypes from './types/mutation_types';

export default {
  async [actionsTypes.FETCH_GUIDES]({ commit }, options) {
    commit(mutationTypes.GUIDES_REQUEST);
    const result = await fetchGuides(options).catch((e) => {
      commit(mutationTypes.GUIDES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.GUIDES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.FETCH_GUIDE]({ commit, getters }, options) {
    commit(mutationTypes.GUIDE_SELECT_REQUEST);
    const id = getters[getterTypes.SELECTED].dish_id;
    const result = await fetchGuide(id, options).catch((e) => {
      commit(mutationTypes.GUIDES_ERROR, e);
    });
    if (result) {
      const { dish, price, branch, id } = result.data;
      commit(mutationTypes.GUIDES_SELECT, {
        id,
        dish_id: dish.id,
        price,
        title: dish.title,
        branch: branch.title,
      });
    } else {
      const res = Object.assign(getters[getterTypes.SELECTED], { price: 0 });
      commit(mutationTypes.GUIDES_SELECT, res);
    }
    return result;
  },
  async [actionsTypes.STORE_GUIDE]({ commit, getters }, payload) {
    commit(mutationTypes.GUIDES_REQUEST);
    const result = await storeGuide(payload).catch((e) => {
      commit(mutationTypes.GUIDES_ERROR, e);
    });
    if (result) {
      const guides = getters[getterTypes.DATA];
      guides.data.unshift(result.data);
      commit(mutationTypes.GUIDES_SUCCESS, guides);
      commit(mutationTypes.GUIDES_SELECT, {
        id: result.data.id,
        dish_id: result.data.dish.id,
        title: result.data.dish.title,
        branch: result.data.branch ? result.data.branch.title : null,
        price: result.data.price,
      });
    }
    return result;
  },
  async [actionsTypes.UPDATE_GUIDE]({ commit, getters, dispatch }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.GUIDES_REQUEST);
    await updateGuide(id, payload).catch((e) => {
      commit(mutationTypes.GUIDES_ERROR, e);
    });
    const filter = appLocalStorage.getItem('filters').guide
      ? appLocalStorage.getItem('filters').guide.value
      : {};
    dispatch(actionsTypes.FETCH_GUIDES, { filter });
  },
  async [actionsTypes.DELETE_GUIDE]({ commit, getters }, id) {
    commit(mutationTypes.GUIDES_REQUEST);
    const result = await deleteGuide(id).catch((e) => {
      commit(mutationTypes.GUIDES_ERROR, e);
    });
    if (result) {
      const guides = getters[getterTypes.DATA];
      guides.data = guides.data.filter((guide) => guide.id !== id);
      commit(mutationTypes.GUIDES_SUCCESS, guides);
    }
    return result;
  },
  [actionsTypes.SELECT_GUIDE]({ commit }, payload) {
    commit(mutationTypes.GUIDES_SELECT, payload);
  },
  [actionsTypes.SELECT_GUIDE_RESET]({ commit }) {
    commit(mutationTypes.GUIDES_SELECT_RESET);
  },
  async [actionsTypes.CALCULATE_COST_PRICE]({ commit }) {
    commit(mutationTypes.GUIDES_LOADING, true);
    const result = await calculateCostPrice();
    commit(mutationTypes.GUIDES_LOADING, false);
    return result;
  },
};
