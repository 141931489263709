import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import {
  deleteCategory,
  fetchCategories,
  storeCategory,
  updateCategory,
} from '../services/categories';

export default {
  async [actionsTypes.FETCH_CATEGORIES]({ commit }, options) {
    commit(mutationTypes.CATEGORIES_REQUEST);
    const result = await fetchCategories(options).catch((e) => {
      commit(mutationTypes.CATEGORIES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CATEGORIES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_CATEGORY]({ commit, getters }, payload) {
    commit(mutationTypes.CATEGORIES_REQUEST);
    const result = await storeCategory(payload).catch((e) => {
      commit(mutationTypes.CATEGORIES_ERROR, e);
    });
    if (result) {
      const categories = getters[getterTypes.DATA];
      categories.data.unshift(result.data);
      commit(mutationTypes.CATEGORIES_SUCCESS, categories);
    }
    return result;
  },
  async [actionsTypes.UPDATE_CATEGORY]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.CATEGORIES_REQUEST);
    await updateCategory(id, payload).catch((e) => {
      commit(mutationTypes.CATEGORIES_ERROR, e);
    });
    commit(mutationTypes.CATEGORIES_SUCCESS, getters[getterTypes.DATA]);
  },
  async [actionsTypes.DELETE_CATEGORY]({ commit, getters }, id) {
    commit(mutationTypes.CATEGORIES_REQUEST);
    const result = await deleteCategory(id).catch((e) => {
      commit(mutationTypes.CATEGORIES_ERROR, e);
    });
    if (result) {
      const categories = getters[getterTypes.DATA];
      categories.data = categories.data.filter((branch) => branch.id !== id);
      commit(mutationTypes.CATEGORIES_SUCCESS, categories);
    }
    return result;
  },
  [actionsTypes.SELECT_CATEGORY]({ commit }, payload) {
    commit(mutationTypes.CATEGORIES_SELECT, payload);
  },
  [actionsTypes.SELECT_CATEGORY_RESET]({ commit }) {
    commit(mutationTypes.CATEGORIES_SELECT_RESET);
  },
};
