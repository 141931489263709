import { appLocalStorage } from '@/app/shared/services';
import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import {
  fetchSemifinisheds,
  fetchSemifinished,
  storeSemifinished,
  updateSemifinished,
  deleteSemifinished,
  storeSemifinishedIngredient,
  updateSemifinishedIngredient,
  deleteSemifinishedIngredient,
} from '../services/semifinisheds';

export default {
  async [actionsTypes.FETCH_SEMIFINISHEDS]({ commit }, options) {
    commit(mutationTypes.SEMIFINISHEDS_REQUEST);
    const result = await fetchSemifinisheds(options).catch((e) => {
      commit(mutationTypes.SEMIFINISHEDS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.SEMIFINISHEDS_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.FETCH_SEMIFINISHED]({ commit }, id) {
    commit(mutationTypes.SEMIFINISHED_SELECT_REQUEST);
    const result = await fetchSemifinished(id).catch((e) => {
      commit(mutationTypes.SEMIFINISHEDS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.SEMIFINISHEDS_SELECT, result.data);
    }
    return result;
  },
  async [actionsTypes.STORE_SEMIFINISHED]({ commit, getters }, payload) {
    commit(mutationTypes.SEMIFINISHEDS_REQUEST);
    const result = await storeSemifinished(payload).catch((e) => {
      commit(mutationTypes.SEMIFINISHEDS_ERROR, e);
    });
    if (result) {
      const guides = getters[getterTypes.DATA];
      guides.data.unshift(result.data);
      commit(mutationTypes.SEMIFINISHEDS_SUCCESS, guides);
    }
    return result;
  },
  async [actionsTypes.UPDATE_SEMIFINISHED]({ commit, getters, dispatch }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.SEMIFINISHEDS_REQUEST);
    await updateSemifinished(id, payload).catch((e) => {
      commit(mutationTypes.SEMIFINISHEDS_ERROR, e);
    });
    const filter = appLocalStorage.getItem('filters').guide
      ? appLocalStorage.getItem('filters').guide.value
      : {};
    dispatch(actionsTypes.FETCH_SEMIFINISHEDS, { filter });
  },
  async [actionsTypes.DELETE_SEMIFINISHED]({ commit, getters }, id) {
    commit(mutationTypes.SEMIFINISHEDS_REQUEST);
    const result = await deleteSemifinished(id).catch((e) => {
      commit(mutationTypes.SEMIFINISHEDS_ERROR, e);
    });
    if (result) {
      const guides = getters[getterTypes.DATA];
      guides.data = guides.data.filter((guide) => guide.id !== id);
      commit(mutationTypes.SEMIFINISHEDS_SUCCESS, guides);
    }
    return result;
  },
  async [actionsTypes.DELETE_SEMIFINISHED_INGREDIENT]({ dispatch, getters }, id) {
    const semifinished = getters[getterTypes.SELECTED];
    await deleteSemifinishedIngredient(semifinished.id, id);
    dispatch(actionsTypes.FETCH_SEMIFINISHED, semifinished.id);
  },
  async [actionsTypes.UPDATE_SEMIFINISHED_INGREDIENT]({ dispatch, getters }, payload) {
    const semifinished = getters[getterTypes.SELECTED];
    await updateSemifinishedIngredient(semifinished.id, payload.ingredient_id, payload);
    dispatch(actionsTypes.FETCH_SEMIFINISHED, semifinished.id);
  },
  async [actionsTypes.STORE_SEMIFINISHED_INGREDIENT]({ dispatch, getters }, payload) {
    const semifinished = getters[getterTypes.SELECTED];
    await storeSemifinishedIngredient(semifinished.id, payload);
    dispatch(actionsTypes.FETCH_SEMIFINISHED, semifinished.id);
  },
  [actionsTypes.SELECT_SEMIFINISHED]({ commit }, payload) {
    commit(mutationTypes.SEMIFINISHEDS_SELECT, payload);
  },
  [actionsTypes.SELECT_SEMIFINISHED_RESET]({ commit }) {
    commit(mutationTypes.SEMIFINISHEDS_SELECT_RESET);
  },
};
