import {
  copyDish,
  deleteDish,
  deleteDishIngredient,
  fetchDish,
  fetchDishes,
  printDish,
  storeDish,
  storeDishIngredient,
  updateDish,
  updateDishIngredient,
} from '../services/dishes';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';
import * as mutationTypes from './types/mutation_types';

export default {
  async [actionsTypes.PRINT_DISH](_, id) {
    const result = await printDish(id);
    if (result) {
      window.location.href = result.data.url;
    }
  },
  async [actionsTypes.COPY_DISH]({ dispatch, commit }, payload) {
    const result = await copyDish({ id: payload.id }).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    if (result) {
      dispatch(actionsTypes.FETCH_DISHES, payload.options);
    }
    return result;
  },
  async [actionsTypes.FETCH_DISHES]({ commit }, options) {
    commit(mutationTypes.DISHES_REQUEST);
    const result = await fetchDishes(options).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.DISHES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_DISH]({ commit, getters }, payload) {
    commit(mutationTypes.DISHES_REQUEST);
    const result = await storeDish(payload).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    if (result) {
      const categories = getters[getterTypes.DATA];
      categories.data.unshift(result.data);
      commit(mutationTypes.DISHES_SUCCESS, categories);
    }
    return result;
  },
  async [actionsTypes.UPDATE_DISH]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.DISHES_REQUEST);
    await updateDish(id, payload).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    commit(mutationTypes.DISHES_SUCCESS, getters[getterTypes.DATA]);
  },
  async [actionsTypes.DELETE_DISH]({ commit, getters }, id) {
    commit(mutationTypes.DISHES_REQUEST);
    const result = await deleteDish(id).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    if (result) {
      const categories = getters[getterTypes.DATA];
      categories.data = categories.data.filter((branch) => branch.id !== id);
      commit(mutationTypes.DISHES_SUCCESS, categories);
    }
    return result;
  },
  async [actionsTypes.SELECT_DISH]({ commit }, id) {
    const result = await fetchDish(id).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.DISHES_SELECT, result.data);
    }
    return result;
  },
  async [actionsTypes.DELETE_DISH_INGREDIENT]({ dispatch, getters }, payload) {
    const dish = getters[getterTypes.SELECTED];
    await deleteDishIngredient(dish.id, payload);
    dispatch(actionsTypes.SELECT_DISH, dish.id);
  },
  async [actionsTypes.UPDATE_DISH_INGREDIENT]({ dispatch, getters }, payload) {
    const dish = getters[getterTypes.SELECTED];
    const { ingredient_id, semis_id } = payload;
    if (ingredient_id) {
      await updateDishIngredient(dish.id, ingredient_id, payload);
    } else {
      await updateDishIngredient(dish.id, semis_id, payload);
    }
    dispatch(actionsTypes.SELECT_DISH, dish.id);
  },
  async [actionsTypes.STORE_DISH_INGREDIENT]({ dispatch, commit, getters }, payload) {
    const dish = getters[getterTypes.SELECTED];
    await storeDishIngredient(dish.id, payload).catch((e) => {
      commit(mutationTypes.DISHES_ERROR, e);
    });
    dispatch(actionsTypes.SELECT_DISH, dish.id);
  },

  [actionsTypes.SELECT_DISH_RESET]({ commit }) {
    commit(mutationTypes.DISHES_SELECT_RESET);
  },
};
