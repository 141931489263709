const dashbordRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'main',
      auth: true,
      menu: false,
      title: 'Панель управления',
      icon: 'el-icon-menu',
      roles: ['super-admin', 'manager', 'admin', 'administrator', 'master-admin'],
    },
    component: () => import('./dashboard.vue'),
  },
];

export default dashbordRoutes;
