import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import { deleteStorage, fetchStorage, storeStorage, updateStorage } from '../services/storage';

export default {
  async [actionsTypes.FETCH_STORAGE]({ commit }, options) {
    commit(mutationTypes.STORAGE_REQUEST);
    const result = await fetchStorage(options).catch((e) => {
      commit(mutationTypes.STORAGE_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.STORAGE_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_STORAGE]({ commit }, payload) {
    commit(mutationTypes.STORAGE_REQUEST);
    const result = await storeStorage(payload).catch((e) => {
      commit(mutationTypes.STORAGE_ERROR, e);
    });
    return result;
  },
  async [actionsTypes.UPDATE_STORAGE]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.STORAGE_REQUEST);
    await updateStorage(id, payload).catch((e) => {
      commit(mutationTypes.STORAGE_ERROR, e);
    });
    commit(mutationTypes.STORAGE_SUCCESS, getters[getterTypes.DATA]);
  },
  async [actionsTypes.DELETE_STORAGE]({ commit, getters }, id) {
    commit(mutationTypes.STORAGE_REQUEST);
    const result = await deleteStorage(id).catch((e) => {
      commit(mutationTypes.STORAGE_ERROR, e);
    });
    if (result) {
      const branches = getters[getterTypes.DATA];
      branches.data = branches.data.filter((branch) => branch.id !== id);
      commit(mutationTypes.STORAGE_SUCCESS, branches);
    }
    return result;
  },
  [actionsTypes.SELECT_STORAGE]({ commit }, payload) {
    commit(mutationTypes.STORAGE_SELECT, payload);
  },
  [actionsTypes.SELECT_STORAGE_RESET]({ commit }) {
    commit(mutationTypes.STORAGE_SELECT_RESET);
  },
};
