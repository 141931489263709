import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchChildrenCycle = (options = {}) => {
  const url = `${apiConstants.cycle}/children${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const fetchCycles = (options = {}) => {
  const url = `${apiConstants.cycles}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchCurrentCycle = () => {
  const url = `${apiConstants.cycleCurrent}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchMenu = (options = {}) => {
  const url = `${apiConstants.menu}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchCycleEvents = (id, options) => {
  const url = `${apiConstants.cycles}/${id}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchCycleData = (id, options) => {
  const url = `${apiConstants.cycles}/${id}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeCycle = (payload) => {
  const url = apiConstants.cycles;
  return httpClient.post(url, payload).then((res) => res.data);
};
const copyCycle = (payload) => {
  const url = `${apiConstants.cycles}/copy`;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateCycle = (id, payload) => {
  const url = `${apiConstants.cycles}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteCycle = (id) => {
  const url = `${apiConstants.cycles}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};
const storeCycleData = (payload) => {
  const url = `${apiConstants.cyclesData}`;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateCycleData = (id, payload) => {
  const url = `${apiConstants.cyclesData}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteCycleData = (id) => {
  const url = `${apiConstants.cyclesData}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export {
  fetchMenu,
  fetchCycles,
  fetchCycleData,
  fetchCycleEvents,
  storeCycle,
  updateCycle,
  deleteCycle,
  storeCycleData,
  updateCycleData,
  deleteCycleData,
  fetchCurrentCycle,
  fetchChildrenCycle,
  copyCycle,
};
