const dishRoutes = [
  {
    path: 'dishes',
    name: 'Dishes',
    meta: {
      layout: 'main',
      title: 'Тех. карта блюда',
      roles: ['super-admin', 'master-admin', 'administrator'],
    },
    component: () => import('./dishes.vue'),
  },
  {
    path: 'dish/:id',
    name: 'Dish',
    meta: {
      layout: 'main',
      title: 'Блюдо',
      roles: ['super-admin', 'master-admin'],
      exclude: true,
    },
    component: () => import('./dish/dish.vue'),
  },
];

export default dishRoutes;
