import { httpClient } from '@/app/shared/services';

const url = `/address`;

export default {
  async STORE_ADDRESS({ dispatch, getters }, data) {
    const clientId = getters.CLIENT.id;
    await httpClient.post(`/clients/${clientId}/address`, data).then(() => {
      dispatch('FETCH_CLIENT', clientId);
    });
  },
  async UPDATE_ADDRESS({ getters, dispatch }, data) {
    const clientId = getters.CLIENT.id;
    await httpClient.put(`${url}/${data.id}`, data).then(() => {
      dispatch('FETCH_CLIENT', clientId);
    });
  },
  async DELETE_ADDRESS({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      commit(
        'SET_ADDRESS',
        getters.ADDRESS.filter((address) => address.id !== id)
      );
    });
  },
};
