<template>
  <el-header class="header">
    <div class="main">
      <el-page-header v-if="$route.path !== '/dashboard'" :content="title" @back="goBack" />
      <div v-else class="main">
        <div class="el-page-header">
          <div class="el-page-header__content">
            {{ title }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="user" v-loading="loading" class="user">
      <figure class="image is-32x32">
        <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
      </figure>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ user.name }} <span v-if="user.branch">({{ user.branch.title }})</span>
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <template slot="dropdown">
          <el-dropdown-menu>
            <el-dropdown-item>Настройки</el-dropdown-item>
            <el-dropdown-item command="logout"> Выход </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as actionsTypes from '@/app/auth/shared/state/types/action_types';
import * as getterTypes from '@/app/auth/shared/state/types/getter_types';

export default {
  name: 'AppHeader',
  computed: {
    ...mapGetters({ user: getterTypes.ME, loading: getterTypes.LOADING }),
    ...mapState({
      titleCycle: (state) => state.cyclesState.cycleData.title,
      client: (state) => state.client.client,
    }),
    title() {
      if (this.$route.name === 'Cycle') {
        return this.titleCycle;
      }
      if (this.$route.path.includes('client') && !this.$route.path.includes('clients')) {
        return `Клиент ${this.client ? this.client.name : ''} - ${this.$route.meta.title}`;
      }
      return this.$route.meta.title;
    },
  },
  async mounted() {
    await this.$store.dispatch(actionsTypes.ME);
  },
  methods: {
    async handleCommand(command) {
      if (command === 'logout') {
        await this.$store.dispatch(actionsTypes.LOGOUT);
        await this.$router.push('/login?message=logout');
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user {
    display: flex;
    align-items: center;
    .image {
      margin-right: 10px;
    }
  }
}
</style>
