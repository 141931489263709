import dateFilter from '@/filters/dateFilter';
import VueCompositionAPI from '@vue/composition-api';
import 'bulma/css/bulma.min.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';
import router from './app/app-routes';
import store from './app/app-state';
import App from './app/app.vue';
import './assets/scss/main.scss';

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.filter('dateFilter', dateFilter);
Vue.use(VueCompositionAPI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
