import { namespacedPrefix } from '@/app/administration/guide/shared/config';
import { reflectKeys } from '@/app/shared/services';

const mutationTypes = reflectKeys(
  [
    'GUIDES_SUCCESS',
    'GUIDES_REQUEST',
    'GUIDES_ERROR',
    'GUIDES_RESET',
    'GUIDES_SELECT',
    'GUIDES_SELECT_RESET',
    'GUIDE_SELECT_REQUEST',
    'GUIDES_LOADING',
  ],
  namespacedPrefix
);

const {
  GUIDES_SUCCESS,
  GUIDES_REQUEST,
  GUIDES_ERROR,
  GUIDES_RESET,
  GUIDES_SELECT,
  GUIDES_SELECT_RESET,
  GUIDE_SELECT_REQUEST,
  GUIDES_LOADING,
} = mutationTypes;

export {
  GUIDES_SUCCESS,
  GUIDES_REQUEST,
  GUIDES_ERROR,
  GUIDES_RESET,
  GUIDES_SELECT,
  GUIDES_SELECT_RESET,
  GUIDE_SELECT_REQUEST,
  GUIDES_LOADING,
};
