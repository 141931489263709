import * as mutationTypes from './mutation_types';

import initialState from './initialState';

export default {
  /** запрос получения пользователя */
  [mutationTypes.DISHES_DATA_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },

  /** успешное получение информации о пользователе */
  [mutationTypes.DISHES_DATA_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },

  /** ошибка получения информации о пользователе */
  [mutationTypes.DISHES_DATA_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },

  /** очистка данных, возвращаем состояние к исходным данных */
  [mutationTypes.DISHES_DATA_RESET](state) {
    Object.assign(state, ...initialState);
  },
};
