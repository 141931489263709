import { httpClient } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchTypes = () => {
  const url = `${apiConstants.types}`;
  return httpClient.get(url).then((res) => res.data);
};

export { fetchTypes };
