import { httpClient } from '@/app/shared/services';

const url = `/branches`;

export default {
  async FETCH_BRANCHES({ commit }, page = 1) {
    await httpClient.get(`${url}?page=${page}`).then((response) => {
      commit('SET_BRANCHES', response.data);
    });
  },
  async STORE_BRANCH({ getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const branches = getters.BRANCHES;
      branches.data.unshift(response.data.data);
    });
  },
  async UPDATE_BRANCH(_, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
  },
  async DELETE_BRANCH({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const branches = getters.BRANCHES;
      branches.data = branches.data.filter((branch) => branch.id !== id);
      commit('SET_BRANCHES', branches);
    });
  },
};
