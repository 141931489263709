export default {
  loading: false,
  data: null,
  error: null,
  selectLoading: false,
  selected: {
    title: null,
  },
  semisIngredients: null,
};
