const branchesRoutes = [
  {
    path: 'branches',
    name: 'Branches',
    meta: {
      layout: 'main',
      title: 'Филиалы',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./branch.vue'),
  },
];

export default branchesRoutes;
