import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/employes/shared/config';

const mutationTypes = reflectKeys(
  [
    'EMPLOYEES_SUCCESS',
    'EMPLOYEES_REQUEST',
    'EMPLOYEES_ERROR',
    'EMPLOYEES_RESET',
    'EMPLOYEES_SELECT',
    'EMPLOYEES_SELECT_RESET',
  ],
  namespacedPrefix
);

const {
  EMPLOYEES_SUCCESS,
  EMPLOYEES_REQUEST,
  EMPLOYEES_ERROR,
  EMPLOYEES_RESET,
  EMPLOYEES_SELECT,
  EMPLOYEES_SELECT_RESET,
} = mutationTypes;

export {
  EMPLOYEES_SUCCESS,
  EMPLOYEES_REQUEST,
  EMPLOYEES_ERROR,
  EMPLOYEES_RESET,
  EMPLOYEES_SELECT,
  EMPLOYEES_SELECT_RESET,
};
