import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.DISHES_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.DISHES_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.DISH_INGREDIENTS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, dishIngredients: payload });
  },
  [mutationTypes.DISH_INGREDIENTS_RESET](state) {
    Object.assign(state, { dishIngredients: null });
  },
  [mutationTypes.DISHES_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },
  [mutationTypes.DISHES_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.DISHES_SELECT](state, payload) {
    Object.assign(state, { loading: false, selected: payload });
  },
  [mutationTypes.DISHES_SELECT_RESET](state) {
    Object.assign(state, { selected: { title: '' } });
  },
};
