import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchStorage = (options = {}) => {
  const url = `${apiConstants.storage}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeStorage = (payload) => {
  const url = apiConstants.storage;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateStorage = (id, payload) => {
  const url = `${apiConstants.storage}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteStorage = (id) => {
  const url = `${apiConstants.storage}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchStorage, storeStorage, updateStorage, deleteStorage };
