import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchUnits = (options = {}) => {
  const url = `${apiConstants.units}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeUnit = (payload) => {
  const url = apiConstants.units;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateUnit = (id, payload) => {
  const url = `${apiConstants.units}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteUnit = (id) => {
  const url = `${apiConstants.units}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchUnits, storeUnit, updateUnit, deleteUnit };
