import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/auth/shared/config';

const gettersTypes = reflectKeys(
  [
    'LOADING',
    'DATA',
    'ERROR',
    'IS_SUPER_ADMIN',
    'IS_MASTER_ADMIN',
    'IS_ADMINISTRATOR',
    'IS_ADMIN',
    'IS_MANAGER',
    'ME',
  ],
  namespacedPrefix
);

const {
  LOADING,
  DATA,
  ERROR,
  IS_SUPER_ADMIN,
  IS_MASTER_ADMIN,
  IS_ADMINISTRATOR,
  IS_ADMIN,
  IS_MANAGER,
  ME,
} = gettersTypes;

export {
  LOADING,
  DATA,
  ERROR,
  IS_SUPER_ADMIN,
  IS_MASTER_ADMIN,
  IS_ADMINISTRATOR,
  IS_ADMIN,
  IS_MANAGER,
  ME,
};
