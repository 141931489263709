import { httpClient } from '@/app/shared/services';

const url = `/units`;

export default {
  async FETCH_UNITS({ commit }, page = 1) {
    await httpClient.get(`${url}?page=${page}`).then((response) => {
      commit('SET_UNITS', response.data);
    });
  },
  async STORE_UNIT({ getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const units = getters.UNITS;
      units.data.unshift(response.data.data);
    });
  },
  async UPDATE_UNIT(_, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
  },
  async DELETE_UNIT({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const units = getters.UNITS;
      units.data = units.data.filter((unit) => unit.id !== id);
      commit('SET_UNITS', units);
    });
  },
};
