import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import {
  deleteEmployee,
  fetchEmployees,
  storeEmployee,
  updateEmployee,
} from '../services/employees';

export default {
  async [actionsTypes.FETCH_EMPLOYEES]({ commit }, options) {
    commit(mutationTypes.EMPLOYEES_REQUEST);
    const result = await fetchEmployees(options).catch((e) => {
      commit(mutationTypes.EMPLOYEES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.EMPLOYEES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_EMPLOYEE]({ commit, getters }, payload) {
    commit(mutationTypes.EMPLOYEES_REQUEST);
    const result = await storeEmployee(payload).catch((e) => {
      commit(mutationTypes.EMPLOYEES_ERROR, e);
    });
    if (result) {
      const employees = getters[getterTypes.DATA];
      employees.data.unshift(result.data);
      commit(mutationTypes.EMPLOYEES_SUCCESS, employees);
    }
    return result;
  },
  async [actionsTypes.UPDATE_EMPLOYEE]({ commit, getters, dispatch }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.EMPLOYEES_REQUEST);
    await updateEmployee(id, payload).catch((e) => {
      commit(mutationTypes.EMPLOYEES_ERROR, e);
    });
    dispatch(actionsTypes.FETCH_EMPLOYEES);
  },
  async [actionsTypes.DELETE_EMPLOYEE]({ commit, getters }, id) {
    commit(mutationTypes.EMPLOYEES_REQUEST);
    const result = await deleteEmployee(id).catch((e) => {
      commit(mutationTypes.EMPLOYEES_ERROR, e);
    });
    if (result) {
      const employees = getters[getterTypes.DATA];
      employees.data = employees.data.filter((employee) => employee.id !== id);
      commit(mutationTypes.EMPLOYEES_SUCCESS, employees);
    }
    return result;
  },
  [actionsTypes.SELECT_EMPLOYEE]({ commit }, payload) {
    commit(mutationTypes.EMPLOYEES_SELECT, payload);
  },
  [actionsTypes.SELECT_EMPLOYEE_RESET]({ commit }) {
    commit(mutationTypes.EMPLOYEES_SELECT_RESET);
  },
};
