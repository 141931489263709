import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '../config';

const mutationTypes = reflectKeys(
  ['DISHES_DATA_SUCCESS', 'DISHES_DATA_REQUEST', 'DISHES_DATA_ERROR', 'DISHES_DATA_RESET'],
  namespacedPrefix
);

const { DISHES_DATA_SUCCESS, DISHES_DATA_REQUEST, DISHES_DATA_ERROR, DISHES_DATA_RESET } =
  mutationTypes;

export { DISHES_DATA_SUCCESS, DISHES_DATA_REQUEST, DISHES_DATA_ERROR, DISHES_DATA_RESET };
