import actions from './actions';
import getters from './getters';
import initialState from './initialState';
import mutations from './mutations';

export default {
  mutations,
  actions,
  getters,
  state: initialState,
};
