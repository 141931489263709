import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/ingredients/shared/config';

const gettersTypes = reflectKeys(
  ['LOADING', 'DATA', 'ERROR', 'SELECTED', 'CATEGORY'],
  namespacedPrefix
);

const { LOADING, DATA, ERROR, SELECTED, CATEGORY } = gettersTypes;

export { LOADING, DATA, ERROR, SELECTED, CATEGORY };
