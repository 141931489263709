import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchSemifinisheds = (options = {}) => {
  const url = `${apiConstants.semifinisheds}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchSemifinished = (id, options = {}) => {
  const url = `${apiConstants.semifinisheds}/${id}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeSemifinished = (payload) => {
  const url = apiConstants.semifinisheds;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateSemifinished = (id, payload) => {
  const url = `${apiConstants.semifinisheds}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteSemifinished = (id) => {
  const url = `${apiConstants.semifinisheds}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};
const storeSemifinishedIngredient = (id, payload) => {
  const url = `${apiConstants.semifinisheds}/${id}/ingredient`;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateSemifinishedIngredient = (semisId, ingredientId, payload) => {
  const url = `${apiConstants.semifinisheds}/${semisId}/ingredient/${ingredientId}`;
  delete payload.ingredient_id;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteSemifinishedIngredient = (semisId, ingredientId) => {
  const url = `${apiConstants.semifinisheds}/${semisId}/ingredient/${ingredientId}`;
  return httpClient.delete(url).then((res) => res.data);
};

export {
  fetchSemifinisheds,
  fetchSemifinished,
  storeSemifinished,
  updateSemifinished,
  deleteSemifinished,
  storeSemifinishedIngredient,
  updateSemifinishedIngredient,
  deleteSemifinishedIngredient,
};
