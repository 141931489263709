export default {
  loading: false,
  data: null,
  error: null,
  selected: {
    name: null,
    email: null,
    password: null,
    role_id: null,
    branch_id: null,
    branch: {
      title: null,
    },
  },
};
