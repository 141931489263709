const courierRoutes = [
  {
    path: '/couriers',
    name: 'couriers',
    meta: {
      layout: 'main',
      auth: true,
      title: 'Курьеры',
      icon: 'el-icon-truck',
      roles: ['admin', 'administrator'],
    },
    component: () => import('./courier-list.vue'),
  },
  {
    path: '/couriers/:id',
    name: 'courier',
    meta: {
      layout: 'main',
      auth: true,
      exclude: true,
      title: 'Курьер',
      roles: ['admin', 'administrator'],
    },
    component: () => import('./courier-view/courier-view.vue'),
  },
];

export default courierRoutes;
