import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/category_ingredients/shared/config';

const mutationTypes = reflectKeys(
  [
    'CAT_ING_SUCCESS',
    'CAT_ING_REQUEST',
    'CAT_ING_ERROR',
    'CAT_ING_RESET',
    'CAT_ING_SELECT',
    'CAT_ING_SELECT_RESET',
  ],
  namespacedPrefix
);

const {
  CAT_ING_SUCCESS,
  CAT_ING_REQUEST,
  CAT_ING_ERROR,
  CAT_ING_RESET,
  CAT_ING_SELECT,
  CAT_ING_SELECT_RESET,
} = mutationTypes;

export {
  CAT_ING_SUCCESS,
  CAT_ING_REQUEST,
  CAT_ING_ERROR,
  CAT_ING_RESET,
  CAT_ING_SELECT,
  CAT_ING_SELECT_RESET,
};
