import { httpClient } from '@/app/shared/services';

const url = `/additions`;

export default {
  async FETCH_ADDITIONS({ commit }, page = 1) {
    await httpClient.get(`${url}?page=${page}`).then((response) => {
      commit('SET_ADDITIONS', response.data);
    });
  },
  async STORE_ADDITIONS({ commit, getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const additions = getters.ADDITIONS;
      additions.data.push(response.data.data);
      commit('SET_ADDITIONS', additions);
    });
  },
  async UPDATE_ADDITIONS({ commit, getters }, data) {
    await httpClient.put(`${url}/${data.id}`, data).then(() => {
      const addition = getters.ADDITIONS;
      const additionIndex = addition.data.findIndex((item) => item.id === data.id);
      addition.data[additionIndex] = { ...addition.data[additionIndex], data };
      commit('SET_ADDITIONS', addition);
    });
  },
  async DELETE_ADDITIONS({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const additions = getters.ADDITIONS;
      additions.data = additions.data.filter((addition) => addition.id !== id);
      commit('SET_ADDITIONS', additions);
    });
  },
};
