import * as mutationTypes from './mutation_types';
import * as actionsTypes from './action_types';

export default {
  async [actionsTypes.FETCH_DISHES_DATA](context) {
    context.commit(mutationTypes.DISHES_DATA_REQUEST);

    // const result = await fetchUsers(authCred).catch((e) => {
    //   context.commit(mutationTypes.DISHES_DATA_ERROR, e);
    // });

    // if (result) {
    //   context.commit(mutationTypes.DISHES_DATA_SUCCESS, result);
    // }

    // return result;
  },
};
