import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/units/shared/config';

const mutationTypes = reflectKeys(
  [
    'UNITS_SUCCESS',
    'UNITS_REQUEST',
    'UNITS_ERROR',
    'UNITS_RESET',
    'UNITS_SELECT',
    'UNITS_SELECT_RESET',
  ],
  namespacedPrefix
);

const { UNITS_SUCCESS, UNITS_REQUEST, UNITS_ERROR, UNITS_RESET, UNITS_SELECT, UNITS_SELECT_RESET } =
  mutationTypes;

export { UNITS_SUCCESS, UNITS_REQUEST, UNITS_ERROR, UNITS_RESET, UNITS_SELECT, UNITS_SELECT_RESET };
