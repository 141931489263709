import { catRoutes } from '@/app/settings/category_ingredients';
import { cyclesRoutes } from './cycles';
import { extraMenuRoutes } from './extra-menu';
import { guideRoutes } from './guide';
import { ingredientsRoutes } from './ingredients';
import { semifishedsRoutes } from './semifisheds';

const childRoutes = [
  ...cyclesRoutes,
  ...extraMenuRoutes,
  ...guideRoutes,
  ...ingredientsRoutes,
  ...catRoutes,
  ...semifishedsRoutes,
];

const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      layout: 'main',
      auth: true,
      menu: true,
      group: true,
      isSuperAdmin: true,
      icon: 'el-icon-s-tools',
      title: 'Административная часть',
      roles: ['super-admin', 'master-admin', 'manager', 'administration'],
    },
    component: () => import('./administration.vue'),
    children: childRoutes,
  },
];

export default adminRoutes;
