import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/semifisheds/shared/config';

const gettersTypes = reflectKeys(
  ['LOADING', 'DATA', 'ERROR', 'SELECTED', 'SELECT_LOADING', 'SEMIFINISHED_INGREDIENTS'],
  namespacedPrefix
);

const { LOADING, DATA, ERROR, SELECTED, SELECT_LOADING, SEMIFINISHED_INGREDIENTS } = gettersTypes;

export { LOADING, DATA, ERROR, SELECTED, SELECT_LOADING, SEMIFINISHED_INGREDIENTS };
