import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/employes/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_EMPLOYEES',
    'STORE_EMPLOYEE',
    'UPDATE_EMPLOYEE',
    'DELETE_EMPLOYEE',
    'SELECT_EMPLOYEE',
    'SELECT_EMPLOYEE_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_EMPLOYEES,
  STORE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  SELECT_EMPLOYEE,
  SELECT_EMPLOYEE_RESET,
} = actionsTypes;

export {
  FETCH_EMPLOYEES,
  STORE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  SELECT_EMPLOYEE,
  SELECT_EMPLOYEE_RESET,
};
