import { httpClient } from '@/app/shared/services';

const url = `/users`;

export default {
  FETCH_USERS({ commit }) {
    httpClient.get(`${url}`).then((response) => {
      commit('SET_USERS', response.data);
    });
  },
  STORE_USER({ commit, getters }, data) {
    httpClient.post(url, data).then((response) => {
      const users = getters.USERS;
      users.data.push(response.data.data);
      commit('SET_USERS', users);
    });
  },
  UPDATE_USER(_, data) {
    httpClient.put(`${url}/${data.id}`, data);
  },
  DELETE_USER({ commit, getters }, id) {
    httpClient.delete(`${url}/${id}`).then(() => {
      const users = getters.USERS;
      users.data = users.data.filter((user) => user.id !== id);
      commit('SET_USERS', users);
    });
  },
};
