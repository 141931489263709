import { httpClient } from '@/app/shared/services';
import parseQueryParams from '@/utils/parseQueryParams';

const url = `/clients`;

export default {
  FETCH_HISTORIES({ commit, getters }, options) {
    const client = getters.CLIENT;
    httpClient.get(`${url}/${client.id}/history${parseQueryParams(options)}`).then((response) => {
      if (response.status === 204) {
        return false;
      }
      commit('SET_HISTORIES', response.data);
    });
  },
};
