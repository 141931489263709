export default {
  SET_CLIENTS(state, clients) {
    state.clients = clients;
  },
  SET_CLIENT(state, client) {
    state.client = client;
  },
  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },
};
