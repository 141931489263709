import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/ingredients/shared/config';

const mutationTypes = reflectKeys(
  [
    'INGREDIENTS_SUCCESS',
    'INGREDIENTS_REQUEST',
    'INGREDIENTS_ERROR',
    'INGREDIENTS_RESET',
    'INGREDIENTS_SELECT',
    'INGREDIENTS_SELECT_RESET',
    'INGREDIENTS_CATEGORY',
  ],
  namespacedPrefix
);

const {
  INGREDIENTS_CATEGORY,
  INGREDIENTS_SUCCESS,
  INGREDIENTS_REQUEST,
  INGREDIENTS_ERROR,
  INGREDIENTS_RESET,
  INGREDIENTS_SELECT,
  INGREDIENTS_SELECT_RESET,
} = mutationTypes;

export {
  INGREDIENTS_CATEGORY,
  INGREDIENTS_SUCCESS,
  INGREDIENTS_REQUEST,
  INGREDIENTS_ERROR,
  INGREDIENTS_RESET,
  INGREDIENTS_SELECT,
  INGREDIENTS_SELECT_RESET,
};
