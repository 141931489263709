import * as getterTypes from './types/getter_types';

export default {
  [getterTypes.LOADING]: (state) => state.loading,
  [getterTypes.DATA]: (state) => state.data,
  [getterTypes.ERROR]: (state) => state.error,
  [getterTypes.LONGER_SUM]: (state) => state.longerSum,
  // [getterTypes.SELECTED]: (state) => state.selected,
  // [getterTypes.CYCLE_EVENTS]: (state) => state.cycleEvents,
  // [getterTypes.CYCLE_DATA]: (state) => state.cycleData,
  // [getterTypes.CYCLE_CURRENT]: (state) => state.cycleCurrent
};
