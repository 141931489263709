import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import interaction from './interaction';
import history from './history';

export default {
  state: {
    clients: {
      data: [],
      links: {},
      meta: {},
    },
    client: null,
    messages: [],
  },
  getters,
  mutations,
  actions,
  modules: {
    interaction,
    history,
  },
};
