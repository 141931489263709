import { SET_KITHCENS, SET_SELECTED } from './mutations_types';

export default {
  [SET_KITHCENS](state, kitchens) {
    state.kitchens = kitchens;
  },
  [SET_SELECTED](state, selected) {
    state.selected = selected;
  },
  SET_DELIVERY(state, delivery) {
    state.delivery = delivery;
  },
  SET_SELECTED_DELIVERY(state, selected) {
    state.selectedDelivery = selected;
  },
};
