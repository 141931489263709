import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/manager/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_TASKS',
    'FETCH_TASK',
    'STORE_TASK',
    'UPDATE_TASK',
    'DELETE_TASK',
    'FETCH_TYPES',
    'SELECT_TASK',
  ],
  namespacedPrefix
);

const { FETCH_TYPES, FETCH_TASKS, FETCH_TASK, STORE_TASK, UPDATE_TASK, DELETE_TASK, SELECT_TASK } =
  actionsTypes;

export { FETCH_TYPES, FETCH_TASKS, FETCH_TASK, STORE_TASK, UPDATE_TASK, DELETE_TASK, SELECT_TASK };
