import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';

const url = `/semifinisheds`;

export default {
  async FETCH_SEMIFINISHEDS({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_SEMIFINISHEDS', response.data);
    });
  },
  async STORE_SEMIFINISHED({ commit, getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const semifinisheds = getters.SEMIFINISHEDS;
      semifinisheds.data.push(response.data.data);
      commit('SET_SEMIFINISHEDS', semifinisheds);
    });
  },
  async UPDATE_SEMIFINISHED(_, data) {
    await httpClient.put(`${url}/${data.id}`, data);
  },
  async DELETE_SEMIFINISHED({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const semifinisheds = getters.SEMIFINISHEDS;
      semifinisheds.data = semifinisheds.data.filter((semifinished) => semifinished.id !== id);
      commit('SET_SEMIFINISHEDS', semifinisheds);
    });
  },
};
