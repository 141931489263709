import { httpClient, parseQueryParam } from '@/app/shared/services';
import { apiConstants } from '../config';

const fetchDishes = (options = {}) => {
  const url = `${apiConstants.dishes}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchDish = (id) => {
  const url = `${apiConstants.dishes}/${id}`;
  return httpClient.get(url).then((res) => res.data);
};
const copyDish = (payload) => {
  const url = `${apiConstants.dishes}/copy`;
  return httpClient.post(url, payload).then((res) => res.data);
};
const storeDish = (payload) => {
  const url = apiConstants.dishes;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateDish = (id, payload) => {
  const url = `${apiConstants.dishes}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteDish = (id) => {
  const url = `${apiConstants.dishes}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};
const storeDishIngredient = (id, payload) => {
  const url = `${apiConstants.dishes}/${id}/ingredient`;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateDishIngredient = (dishId, ingredientId, payload) => {
  const url = `${apiConstants.dishes}/${dishId}/ingredient/${ingredientId}`;
  delete payload.ingredient_id;
  delete payload.semis_id;
  return httpClient.put(url, payload).then((res) => res.data);
};
const deleteDishIngredient = (dishId, payload) => {
  const url = `${apiConstants.dishes}/${dishId}/ingredient/${payload.id}?action=${payload.action}`;
  return httpClient.delete(url).then((res) => res.data);
};

const printDish = (dishId) => {
  const url = `/dishes/${dishId}/file`;
  return httpClient.get(url).then((res) => res.data);
};

export {
  fetchDishes,
  storeDish,
  updateDish,
  deleteDish,
  storeDishIngredient,
  deleteDishIngredient,
  fetchDish,
  updateDishIngredient,
  copyDish,
  printDish,
};
