import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/category_ingredients/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_CAT_ING',
    'STORE_CAT_ING',
    'UPDATE_CAT_ING',
    'DELETE_CAT_ING',
    'SELECT_CAT_ING',
    'SELECT_CAT_ING_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_CAT_ING,
  STORE_CAT_ING,
  UPDATE_CAT_ING,
  DELETE_CAT_ING,
  SELECT_CAT_ING,
  SELECT_CAT_ING_RESET,
} = actionsTypes;

export {
  FETCH_CAT_ING,
  STORE_CAT_ING,
  UPDATE_CAT_ING,
  DELETE_CAT_ING,
  SELECT_CAT_ING,
  SELECT_CAT_ING_RESET,
};
