import Vue from 'vue';
import Vuex from 'vuex';
import { httpClient } from '@/app/shared/services';

// TODO: Рефакторинг
import auth from '@/store/modules/auth';
import meal from '@/store/modules/meal';
import employe from '@/store/modules/employe';
import semis from '@/store/modules/semis';
import ingredient from '@/store/modules/ingredient';
import category from '@/store/modules/category';
import branch from '@/store/modules/branch';
import unit from '@/store/modules/unit';
import directory from '@/store/modules/directory';
import user from '@/store/modules/user';
import dish from '@/store/modules/dish';
import client from '@/store/modules/client';
import tasks from '@/store/modules/tasks';
import address from '@/store/modules/address';
import excluded from '@/store/modules/excluded';
import addition from '@/store/modules/addition';
import order from '@/store/modules/order';
import menu from '@/store/modules/menu';
import kitchen from '@/store/modules/kitchen';
import { authState } from './auth';
import { storageState } from './storage';
import { clientsState } from './clients';
import { managerState } from './manager';
import { semifishedsState } from './administration/semifisheds';
import { guideState } from './administration/guide';
import { cyclesState } from './administration/cycles';
import { ingredientsState } from './administration/ingredients';
import { employesState } from './settings/employes';
import { unitsState } from './settings/units';
import { catState } from './settings/category_ingredients';
import { mealsState } from './settings/meals';
import { categoriesState } from './settings/category';
import { branchesState } from './settings/branches';
import { dishesState } from './settings/dishes';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async FETCH_STATISTICS() {
      return httpClient.get(`/statistic`).then((response) => response.data.data);
    },
    async UPLOAD_FILE(_, payload) {
      return httpClient.post(`/upload`, payload).then((response) => response.data.data);
    },
    WRITE_OFF(_, positions) {
      return httpClient.post(`/writeoff`, { positions }).then((response) => response.data.data);
    },
    SEND_TELEGRAM(_, data) {
      let message = 'Надо приготовить ';
      data.forEach((item) => {
        const arr = [];
        item.excluded.forEach((ex) => {
          Object.keys(ex).forEach((key) => {
            message += `${item.category} без (${key}) в количестве ${ex[key]} , `;
            arr.push(ex[key]);
          });
        });
        message += `${item.category} в количестве ${
          item.count - arr.reduce((acc, current) => acc + current, 0)
        } , `;
      });
      httpClient.get(
        `https://api.telegram.org/bot1534136561:AAENUzFypUKp-LZKwu06Id85zLOF5FUh94s/sendMessage?chat_id=@test_eat&text=${message}`
      );
    },
  },
  modules: {
    dishesState,
    authState,
    branchesState,
    categoriesState,
    mealsState,
    unitsState,
    employesState,
    ingredientsState,
    cyclesState,
    guideState,
    clientsState,
    storageState,
    semifishedsState,
    catState,
    managerState,
    auth,
    meal,
    unit,
    employe,
    branch,
    category,
    semis,
    ingredient,
    directory,
    user,
    dish,
    client,
    tasks,
    address,
    excluded,
    addition,
    order,
    menu,
    kitchen,
  },
});
