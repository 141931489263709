const deliveryRoutes = [
  {
    path: '/delivery',
    name: 'Delivery',
    meta: {
      group: false,
      layout: 'main',
      title: 'Доставка',
      icon: 'el-icon-s-goods',
      isSuperAdmin: false,
      roles: ['super-admin', 'admin', 'administrator', 'master-admin'],
    },
    component: () => import('@/views/kitchens/delivery/Delivery'),
  },
];

export default deliveryRoutes;
