import { namespacedPrefix } from '@/app/settings/dishes/shared/config';
import { reflectKeys } from '@/app/shared/services';

const actionsTypes = reflectKeys(
  [
    'FETCH_DISHES',
    'STORE_DISH',
    'UPDATE_DISH',
    'DELETE_DISH',
    'COPY_DISH',
    'SELECT_DISH',
    'SELECT_DISH_RESET',
    'FETCH_DISH_INGREDIENTS',
    'STORE_DISH_INGREDIENT',
    'UPDATE_DISH_INGREDIENT',
    'DELETE_DISH_INGREDIENT',
    'PRINT_DISH',
  ],
  namespacedPrefix
);

const {
  FETCH_DISHES,
  COPY_DISH,
  STORE_DISH,
  UPDATE_DISH,
  DELETE_DISH,
  SELECT_DISH,
  SELECT_DISH_RESET,
  FETCH_DISH_INGREDIENTS,
  STORE_DISH_INGREDIENT,
  DELETE_DISH_INGREDIENT,
  UPDATE_DISH_INGREDIENT,
  PRINT_DISH,
} = actionsTypes;

export {
  FETCH_DISHES,
  COPY_DISH,
  STORE_DISH,
  UPDATE_DISH,
  DELETE_DISH,
  SELECT_DISH,
  SELECT_DISH_RESET,
  FETCH_DISH_INGREDIENTS,
  STORE_DISH_INGREDIENT,
  DELETE_DISH_INGREDIENT,
  UPDATE_DISH_INGREDIENT,
  PRINT_DISH,
};
