import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/guide/shared/config';

const gettersTypes = reflectKeys(
  ['LOADING', 'DATA', 'ERROR', 'SELECTED', 'SELECT_LOADING'],
  namespacedPrefix
);

const { LOADING, DATA, ERROR, SELECTED, SELECT_LOADING } = gettersTypes;

export { LOADING, DATA, ERROR, SELECTED, SELECT_LOADING };
