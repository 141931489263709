import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/dishes/shared/config';

const gettersTypes = reflectKeys(
  ['LOADING', 'DATA', 'ERROR', 'SELECTED', 'DISH_INGREDIENTS'],
  namespacedPrefix
);

const { LOADING, DATA, ERROR, SELECTED, DISH_INGREDIENTS } = gettersTypes;

export { LOADING, DATA, ERROR, SELECTED, DISH_INGREDIENTS };
