import moment from 'moment';

const groupByDate = (data) => {
  const groups = data.reduce((groups, item) => {
    const date = item.date || item.value;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
  const groupArrays = Object.keys(groups).map((date) => ({
    label: moment(date).locale('ru').format('LL'),
    value: date,
    tasks: groups[date],
  }));
  return groupArrays.sort((a, b) => {
    if (a.label) {
      return +a.label.split(' ')[0] - +b.label.split(' ')[0];
    }
  });
};
export default groupByDate;
