import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.LOGIN_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },

  [mutationTypes.LOGIN_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },

  [mutationTypes.LOGIN_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },

  [mutationTypes.LOGIN_RESET](state) {
    Object.assign(state, ...initialState);
  },

  [mutationTypes.FETCH_ME_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, me: payload });
  },

  [mutationTypes.SET_ROLE](state, payload) {
    Object.assign(state, { role: payload });
  },
};
