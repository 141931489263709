import {
  copyCycle,
  deleteCycle,
  deleteCycleData,
  fetchChildrenCycle,
  fetchCurrentCycle,
  fetchCycleData,
  fetchCycleEvents,
  fetchCycles,
  fetchMenu,
  storeCycle,
  storeCycleData,
  updateCycle,
} from '../services/cycles';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';
import * as mutationTypes from './types/mutation_types';

export default {
  async [actionsTypes.COPY_CYCLE]({ commit, dispatch }, payload) {
    const result = await copyCycle(payload).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      dispatch(actionsTypes.FETCH_CYCLES);
    }
    return result;
  },
  async [actionsTypes.FETCH_CYCLES]({ commit }, options) {
    commit(mutationTypes.CYCLES_REQUEST);
    const result = await fetchCycles(options).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CYCLES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.FETCH_CYCLE_CURRENT]({ commit }) {
    commit(mutationTypes.CYCLES_REQUEST);
    const result = await fetchCurrentCycle().catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CYCLES_CURRENT_SUCCESS, result.data);
    }
    return result;
  },
  async [actionsTypes.FETCH_MENU]({ commit }, options) {
    commit(mutationTypes.MENU_REQUEST);
    const result = await fetchMenu(options).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.MENU_SUCCESS, result.data);
    }
    return result;
  },
  async [actionsTypes.FETCH_CYCLE_EVENTS]({ commit, getters }, { cycleId, params: { type } }) {
    commit(mutationTypes.CYCLES_REQUEST);
    const id = cycleId || getters[getterTypes.SELECTED].id;
    const result = await fetchCycleEvents(id, { type }).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result.data) {
      const { data } = result;
      commit(mutationTypes.CYCLES_EVENTS_SUCCESS, { [type]: data });
    }
    return result;
  },
  async [actionsTypes.FETCH_CYCLE_DATA]({ commit, getters }, { cycleId, date }) {
    commit(mutationTypes.CYCLES_REQUEST);
    const id = cycleId || getters[getterTypes.SELECTED].id;
    const result = await fetchCycleData(id, { date }).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CYCLES_DATA_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_CYCLE]({ commit, dispatch }, payload) {
    commit(mutationTypes.CYCLES_REQUEST);
    await storeCycle(payload).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    dispatch(actionsTypes.FETCH_CYCLES, { excluded: 0 });
  },
  async [actionsTypes.UPDATE_CYCLE]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.CYCLES_REQUEST);
    const result = await updateCycle(id, payload).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      const cycles = getters[getterTypes.DATA];
      const cycle = cycles.data.find((cycle) => cycle.id === result.data.id);
      Object.assign(cycle, { ...result.data });
      commit(mutationTypes.CYCLES_SUCCESS, getters[getterTypes.DATA]);
    }
  },
  async [actionsTypes.DELETE_CYCLE](_, id) {
    const result = await deleteCycle(id);
    return result;
  },
  [actionsTypes.SELECT_CYCLE]({ commit }, payload) {
    commit(mutationTypes.CYCLES_SELECT, payload);
  },
  async [actionsTypes.DELETE_CYCLE_DATA]({ dispatch, commit, getters }, { id, date }) {
    const cycleId = getters[getterTypes.SELECTED].id;
    commit(mutationTypes.CYCLES_REQUEST);
    await deleteCycleData(id);
    dispatch(actionsTypes.FETCH_CYCLE_EVENTS, cycleId);
    dispatch(actionsTypes.FETCH_CYCLE_DATA, { date });
  },
  async [actionsTypes.STORE_CYCLE_DATA]({ dispatch, commit, getters }, payload) {
    const id = payload.cycle_id ? payload.cycle_id : getters[getterTypes.SELECTED].id;
    commit(mutationTypes.CYCLES_REQUEST);
    await storeCycleData(payload);
    dispatch(actionsTypes.FETCH_CYCLE_EVENTS, id);
    dispatch(actionsTypes.FETCH_CYCLE_DATA, { date: payload.date, cycleId: id });
  },
  [actionsTypes.SELECT_CYCLE_RESET]({ commit }) {
    commit(mutationTypes.CYCLES_SELECT_RESET);
  },
  [actionsTypes.CYCLE_DATA_RESET]({ commit }) {
    commit(mutationTypes.CYCLES_DATA_RESET);
  },
  [actionsTypes.CYCLE_EVENTS_RESET]({ commit }) {
    commit(mutationTypes.CYCLES_EVENTS_RESET);
  },
  async [actionsTypes.FETCH_CHILDREN]({ commit }, options) {
    const result = await fetchChildrenCycle(options).catch((e) => {
      commit(mutationTypes.CYCLES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CHILDREN_SUCCESS, result);
    }
    return result;
  },
};
