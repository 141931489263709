import * as getterTypes from './types/getter_types';

export default {
  [getterTypes.LOADING]: (state) => state.loading,
  [getterTypes.DATA]: (state) => state.data,
  [getterTypes.ERROR]: (state) => state.error,
  [getterTypes.SELECTED]: (state) => state.selected,
  [getterTypes.SELECT_LOADING]: (state) => state.selectLoading,
  [getterTypes.SEMIFINISHED_INGREDIENTS]: (state) => state.semisIngredients,
};
