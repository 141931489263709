import { httpClient } from '@/app/shared/services';

import parseQueryParams from '@/utils/parseQueryParams';

const url = `/directory`;

export default {
  async FETCH_DIRECTORIES({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_DIRECTORIES', response.data);
    });
  },
  async STORE_DIRECTORY({ commit, getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const directories = getters.DIRECTORIES;
      directories.data.push(response.data.data);
      commit('SET_DIRECTORIES', directories);
    });
  },
  async UPDATE_DIRECTORY(_, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
  },
  async DELETE_DIRECTORY({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const directories = getters.DIRECTORIES;
      directories.data.filter((directory) => directory.id !== id);
      commit('SET_DIRECTORIES', directories);
    });
  },
};
