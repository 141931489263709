import { httpClient } from '@/app/shared/services';
import parseQueryParams from '@/utils/parseQueryParams';

const url = `/clients`;

export default {
  FETCH_INTERACTIONS({ commit, getters }, options) {
    const client = getters.CLIENT;
    httpClient
      .get(`${url}/${client.id}/interactions${parseQueryParams(options)}`)
      .then((response) => {
        if (response.status === 204) {
          return false;
        }
        commit('SET_INTERACTIONS', response.data);
      });
  },
  STORE_INTERACTION({ dispatch, getters }, payload) {
    const client = getters.CLIENT;
    httpClient.post(`${url}/${client.id}/interactions`, payload);
    dispatch('FETCH_INTERACTIONS');
  },
  UPDATE_INTERACTION({ dispatch }, payload) {
    httpClient.put(`${url}/interactions/${payload.id}`, payload);
    dispatch('FETCH_INTERACTIONS', {});
  },
  DELETE_INTERACTION({ commit, getters }, id) {
    httpClient.delete(`${url}/interactions/${id}`).then(() => {
      const intreactions = getters.INTERACTIONS;
      intreactions.data = intreactions.data.filter((inter) => inter.id !== id);
      commit('SET_INTERACTIONS', intreactions);
    });
  },
  RESET_INTERACTIONS({ commit }) {
    commit('SET_INTERACTIONS', []);
  },
};
