export default {
  loading: false,
  data: null,
  error: null,
  selected: null,
  longerSum: 0,
  client: null,
  clients: null,
  transactions: null,
  orders_cal: null,
};
