import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/category/shared/config';

const mutationTypes = reflectKeys(
  [
    'CATEGORIES_SUCCESS',
    'CATEGORIES_REQUEST',
    'CATEGORIES_ERROR',
    'CATEGORIES_RESET',
    'CATEGORIES_SELECT',
    'CATEGORIES_SELECT_RESET',
  ],
  namespacedPrefix
);

const {
  CATEGORIES_SUCCESS,
  CATEGORIES_REQUEST,
  CATEGORIES_ERROR,
  CATEGORIES_RESET,
  CATEGORIES_SELECT,
  CATEGORIES_SELECT_RESET,
} = mutationTypes;

export {
  CATEGORIES_SUCCESS,
  CATEGORIES_REQUEST,
  CATEGORIES_ERROR,
  CATEGORIES_RESET,
  CATEGORIES_SELECT,
  CATEGORIES_SELECT_RESET,
};
