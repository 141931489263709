export default {
  loading: false,
  data: null,
  error: null,
  selected: null,
  cycleEvents: null,
  cycleData: null,
  cycleCurrent: null,
  menu: null,
  children: null,
  loadingMenu: false,
  options: {
    permissions: {
      edit: ['super-admin', 'master-admin'],
      show: ['super-admin', 'master-admin', 'manager', 'admin', 'administrator'],
    },
  },
  title: '',
};
