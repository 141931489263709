import { httpClient } from '@/app/shared/services';

const url = `/employees`;

export default {
  async FETCH_EMPLOYEES({ commit }, page = 1) {
    await httpClient.get(`${url}?page=${page}`).then((response) => {
      commit('SET_EMPLOYEES', response.data.data);
    });
  },
  async STORE_EMPLOYEE({ commit, getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const employees = getters.EMPLOYEES;
      employees.push(response.data.data);
      commit('SET_EMPLOYEES', employees);
    });
  },
  async UPDATE_EMPLOYEE({ commit, getters }, data) {
    await httpClient.put(`${url}/${data.id}`, data).then(() => {
      const employeeIndex = getters.EMPLOYEES.findIndex((employee) => employee.id === data.id);
      const employees = [...getters.EMPLOYEES];
      employees[employeeIndex] = { ...employees[employeeIndex], data };
      commit('SET_EMPLOYEES', employees);
    });
  },
  async DELETE_EMPLOYEE({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      commit(
        'SET_EMPLOYEES',
        getters.EMPLOYEES.filter((employee) => employee.id !== id)
      );
    });
  },
};
