import { namespacedPrefix } from '@/app/clients/shared/config';
import { reflectKeys } from '@/app/shared/services';

const actionsTypes = reflectKeys(
  [
    'FETCH_ORDERS',
    'STORE_ORDERS',
    'UPDATE_ORDERS',
    'FETCH_ORDER',
    'FETCH_LONGER',
    'FETCH_CLIENTS',
    'STORE_CLIENT',
    'DELETE_CLIENT',
    'UPDATE_CLIENT',
    'FETCH_CLIENT',
    'RESET_CLIENTS',
    'FETCH_TRANSACTIONS',
    'DELETE_ORDER',
  ],
  namespacedPrefix
);

const {
  FETCH_ORDERS,
  STORE_ORDERS,
  FETCH_ORDER,
  FETCH_LONGER,
  FETCH_CLIENTS,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  FETCH_CLIENT,
  STORE_CLIENT,
  RESET_CLIENTS,
  UPDATE_ORDERS,
  FETCH_TRANSACTIONS,
  DELETE_ORDER,
} = actionsTypes;

export {
  FETCH_ORDERS,
  STORE_ORDERS,
  FETCH_ORDER,
  FETCH_LONGER,
  FETCH_CLIENTS,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  FETCH_CLIENT,
  STORE_CLIENT,
  UPDATE_ORDERS,
  RESET_CLIENTS,
  FETCH_TRANSACTIONS,
  DELETE_ORDER,
};
