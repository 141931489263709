const managerRoutes = [
  {
    path: '/manager',
    name: 'Manager',
    meta: {
      layout: 'main',
      icon: 'el-icon-s-management',
      title: 'Доска задач',
      auth: true,
      menu: false,
      roles: ['super-admin', 'admin', 'administrator', 'master-admin'],
    },
    component: () => import('./manager.vue'),
  },
];

export default managerRoutes;
