const districtRoutes = [
  {
    path: 'districts',
    name: 'Districts',
    meta: {
      layout: 'main',
      title: 'Районы',
      roles: ['administrator', 'admin'],
    },
    component: () => import('./district-list.vue'),
  },
];

export default districtRoutes;
