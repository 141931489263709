import { appLocalStorage } from '@/app/shared/services';
import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import { login, fetchMe } from '../services/auth';

export default {
  async [actionsTypes.LOGIN]({ commit }, authCred) {
    commit(mutationTypes.LOGIN_REQUEST);
    const result = await login(authCred).catch((e) => {
      commit(mutationTypes.LOGIN_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.LOGIN_SUCCESS, result);
      appLocalStorage.setItem('role', result.data.role);
      appLocalStorage.setItem('isLoggedIn', true);
      appLocalStorage.setItem('x-jwt-token', result.data.token);
    }
    return result;
  },
  async [actionsTypes.LOGOUT]() {
    appLocalStorage.removeItem('isLoggedIn');
    appLocalStorage.removeItem('x-jwt-token');
  },
  async [actionsTypes.ME]({ commit }) {
    commit(mutationTypes.LOGIN_REQUEST);
    const result = await fetchMe().catch((e) => {
      commit(mutationTypes.LOGIN_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.FETCH_ME_SUCCESS, result.data);
      commit(mutationTypes.SET_ROLE, result.data.roles[0].name);
    }
    return result;
  },
};
