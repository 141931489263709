import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';

const url = `/categories`;

export default {
  async FETCH_CATEGORIES({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_CATEGORIES', response.data);
    });
  },
  async STORE_CATEGORY({ getters }, data) {
    await httpClient.post(url, data).then((response) => {
      const categories = getters.CATEGORIES;
      categories.data.unshift(response.data.data);
    });
  },
  async UPDATE_CATEGORY(_, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
  },
  async DELETE_CATEGORY({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const categories = getters.CATEGORIES;
      categories.data = categories.data.filter((category) => category.id !== id);
      commit('SET_CATEGORIES', categories);
    });
  },
};
