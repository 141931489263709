import { namespacedPrefix } from '@/app/administration/cycles/shared/config';
import { reflectKeys } from '@/app/shared/services';

const mutationTypes = reflectKeys(
  [
    'CYCLES_SUCCESS',
    'CYCLES_REQUEST',
    'CYCLES_ERROR',
    'CYCLES_RESET',
    'CYCLES_SELECT',
    'CYCLES_SELECT_RESET',
    'CYCLES_DATA_SUCCESS',
    'CYCLES_DATA_RESET',
    'CYCLES_EVENTS_SUCCESS',
    'CYCLES_EVENTS_RESET',
    'CYCLES_CURRENT_SUCCESS',
    'CYCLES_CURRENT_RESET',
    'MENU_REQUEST',
    'MENU_SUCCESS',
    'MENU_RESET',
    'CHILDREN_SUCCESS',
    'CYCLE_SET_TITLE',
  ],
  namespacedPrefix
);

const {
  CYCLES_SUCCESS,
  CYCLES_REQUEST,
  CYCLES_ERROR,
  CYCLES_RESET,
  CYCLES_SELECT,
  CYCLES_SELECT_RESET,
  CYCLES_DATA_SUCCESS,
  CYCLES_DATA_RESET,
  CYCLES_EVENTS_SUCCESS,
  CYCLES_EVENTS_RESET,
  CYCLES_CURRENT_SUCCESS,
  CYCLES_CURRENT_RESET,
  MENU_SUCCESS,
  MENU_RESET,
  MENU_REQUEST,
  CHILDREN_SUCCESS,
  CYCLE_SET_TITLE,
} = mutationTypes;

export {
  CYCLES_SUCCESS,
  CYCLES_REQUEST,
  CYCLES_ERROR,
  CYCLES_RESET,
  CYCLES_SELECT,
  CYCLES_SELECT_RESET,
  CYCLES_DATA_SUCCESS,
  CYCLES_DATA_RESET,
  CYCLES_EVENTS_SUCCESS,
  CYCLES_EVENTS_RESET,
  CYCLES_CURRENT_SUCCESS,
  CYCLES_CURRENT_RESET,
  MENU_SUCCESS,
  MENU_RESET,
  MENU_REQUEST,
  CHILDREN_SUCCESS,
  CYCLE_SET_TITLE,
};
