import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchTasks = (options = {}) => {
  const url = `${apiConstants.manager}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const fetchTask = (id) => {
  const url = `${apiConstants.manager}/${id}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeTask = (payload) =>
  httpClient.post(apiConstants.manager, payload).then((res) => res.data);

const updateTask = (id, payload) => {
  const url = `${apiConstants.manager}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteTask = (id) => {
  const url = `${apiConstants.manager}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchTasks, fetchTask, storeTask, updateTask, deleteTask };
