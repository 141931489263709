import initialState from './initialState';
import * as mutationTypes from './types/mutation_types';

export default {
  [mutationTypes.GUIDES_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.GUIDES_LOADING](state, loading) {
    Object.assign(state, { loading });
  },
  [mutationTypes.GUIDES_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.GUIDES_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      error: payload || true,
    });
  },
  [mutationTypes.GUIDES_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.GUIDE_SELECT_REQUEST](state) {
    Object.assign(state, { selectLoading: true, error: null });
  },
  [mutationTypes.GUIDES_SELECT](state, payload) {
    Object.assign(state, { selectLoading: false, selected: payload });
  },
  [mutationTypes.GUIDES_SELECT_RESET](state) {
    Object.assign(state, { selected: { title: '' } });
  },
};
