const employesRoutes = [
  {
    path: 'employees',
    name: 'Employees',
    meta: {
      layout: 'main',
      title: 'Сотрудники',
      roles: ['super-admin', 'master-admin', 'administrator'],
    },
    component: () => import('./employes.vue'),
  },
];

export default employesRoutes;
