import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: {
    interactions: {
      data: [],
      links: {},
      meta: {},
    },
  },
  getters,
  mutations,
  actions,
};
