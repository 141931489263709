import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/administration/cycles/shared/config';

const gettersTypes = reflectKeys(
  [
    'LOADING',
    'DATA',
    'DATA_EXCLUDED',
    'ERROR',
    'SELECTED',
    'CYCLE_EVENTS',
    'CYCLE_DATA',
    'CYCLE_CURRENT',
    'MENU',
    'MENU_LOADING',
  ],
  namespacedPrefix
);

const {
  LOADING,
  DATA,
  ERROR,
  SELECTED,
  CYCLE_EVENTS,
  DATA_EXCLUDED,
  CYCLE_DATA,
  CYCLE_CURRENT,
  MENU,
  MENU_LOADING,
} = gettersTypes;

export {
  LOADING,
  DATA,
  ERROR,
  SELECTED,
  CYCLE_EVENTS,
  DATA_EXCLUDED,
  CYCLE_DATA,
  CYCLE_CURRENT,
  MENU,
  MENU_LOADING,
};
