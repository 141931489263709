import initialState from './initialState';
import * as mutationTypes from './types/mutation_types';

export default {
  [mutationTypes.CYCLES_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.CHILDREN_SUCCESS](state, payload) {
    Object.assign(state, { children: payload });
  },
  [mutationTypes.CYCLES_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.CYCLES_DATA_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, cycleData: payload });
  },
  [mutationTypes.CYCLES_DATA_RESET](state) {
    Object.assign(state, { cycleData: null });
  },
  [mutationTypes.CYCLES_EVENTS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, cycleEvents: payload });
  },
  [mutationTypes.CYCLES_EVENTS_RESET](state) {
    Object.assign(state, { cycleEvents: [] });
  },
  [mutationTypes.CYCLES_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      error: payload || true,
    });
  },
  [mutationTypes.CYCLES_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.CYCLES_SELECT](state, payload) {
    Object.assign(state, { loading: false, selected: payload });
  },
  [mutationTypes.CYCLES_SELECT_RESET](state) {
    Object.assign(state, { selected: null });
  },
  [mutationTypes.CYCLES_CURRENT_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, cycleCurrent: payload });
  },
  [mutationTypes.CYCLES_CURRENT_RESET](state) {
    Object.assign(state, { cycleCurrent: null });
  },
  [mutationTypes.MENU_SUCCESS](state, payload) {
    Object.assign(state, { loadingMenu: false, menu: payload });
  },
  [mutationTypes.MENU_RESET](state) {
    Object.assign(state, { menu: null });
  },
  [mutationTypes.MENU_REQUEST](state) {
    Object.assign(state, { loadingMenu: true, error: null });
  },
  [mutationTypes.CYCLE_SET_TITLE](state, title) {
    Object.assign(state, { title });
  },
};
