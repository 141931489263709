import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/settings/category/shared/config';

const actionsTypes = reflectKeys(
  [
    'FETCH_CATEGORIES',
    'STORE_CATEGORY',
    'UPDATE_CATEGORY',
    'DELETE_CATEGORY',
    'SELECT_CATEGORY',
    'SELECT_CATEGORY_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_CATEGORIES,
  STORE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SELECT_CATEGORY,
  SELECT_CATEGORY_RESET,
} = actionsTypes;

export {
  FETCH_CATEGORIES,
  STORE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SELECT_CATEGORY,
  SELECT_CATEGORY_RESET,
};
