<template>
  <div class="app-table">
    <el-table
      v-if="pagination ? (isArray(data) ? true : data.data) : data"
      v-loading="loading"
      :data="filter()"
      style="width: 100%"
    >
      <slot name="column">
        <el-table-column
          v-for="(field, index) in fields"
          :key="index"
          :prop="field.value"
          :label="field.label"
          :width="field.width ? field.width : 'auto'"
          :formatter="defaultFormatter"
        />
      </slot>
      <slot name="rules">
        <el-table-column v-if="rules.length" width="120" align="right" label="Действия">
          <template v-slot="scope">
            <el-button
              v-for="(rule, index) in rules"
              :key="index"
              size="mini"
              :type="rule.type"
              :icon="rule.icon"
              class="margin-right"
              @click="handleClickAction(scope.$index, scope.row, rule.value)"
            />
          </template>
        </el-table-column>
      </slot>
    </el-table>
    <el-pagination
      v-if="data && pagination"
      class="mt-4"
      background
      layout="prev, pager, next"
      :total="data.hasOwnProperty('meta') ? data.meta.total : 0"
      :page-size="data.hasOwnProperty('meta') ? data.meta.per_page : 0"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'AppTable',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    actions: {
      type: String,
      default: '',
    },
    data: {
      type: [Array, Object],
      required: true,
    },
    fields: {
      required: true,
      type: Array,
    },
    rules: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    searchField: {
      type: String,
      default: 'dish.title',
    },
  },
  data() {
    return { search: '' };
  },
  methods: {
    handleClickAction(_, row, value) {
      this.$emit(value, row);
    },
    handleCurrentChange(page) {
      this.$emit('reload', page);
    },
    filter() {
      const filterFnc = (value) =>
        !this.search || value[this.searchField].toLowerCase().includes(this.search.toLowerCase());

      if (this.pagination) {
        if (Array.isArray(this.data)) {
          return [];
        }
        return this.data.data.filter(filterFnc);
      }
      return this.data.filter(filterFnc);
    },
    defaultFormatter(row, column, cellValue) {
      if (column.property === 'state') {
        return cellValue ? 'Выполнена' : 'Не выполнена';
      }
      return cellValue !== null ? cellValue : '-';
    },
    isArray(obj) {
      return Array.isArray(obj);
    },
  },
};
</script>
