const documentsRoutes = [
  {
    path: '/documents',
    name: 'documents',
    meta: {
      icon: 'el-icon-document',
      title: 'Документы',
      layout: 'main',
      group: true,
      auth: true,
      menu: true,
      roles: ['administrator', 'admin', 'manager'],
    },
    component: () => import('./documents-tabs.vue'),
    children: [
      {
        path: 'kitchens',
        name: 'kitchen-documents',
        meta: {
          layout: 'main',
          title: 'Кухня',
          roles: ['administrator', 'admin', 'manager'],
        },
        component: () => import('./kitchen-documents/kitchen-documents-list.vue'),
      },
      {
        path: 'pickers',
        name: 'picker-documents',
        meta: {
          layout: 'main',
          title: 'Комплектовщик',
          roles: ['administrator', 'admin', 'manager'],
        },
        component: () => import('./picker-documents/picker-documents-list.vue'),
      },
    ],
  },
];

export default documentsRoutes;
