import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: {
    ingredients: [],
    ingredientCategories: [],
  },
  getters,
  mutations,
  actions,
};
