import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import initialState from './initialState';

export default {
  state: initialState,
  actions,
  getters,
  mutations,
};
