import { httpClient } from '@/app/shared/services';
import parseQueryParams from '@/utils/parseQueryParams';
import { SET_KITHCENS } from './mutations_types';

const url = `/kitchens`;

export default {
  FETCH_KITCHENS({ commit }, options) {
    httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit(SET_KITHCENS, response.data.data);
    });
  },
  UPDATE_KITCHENS(_, data) {
    httpClient.put(`${url}${parseQueryParams({ date: data.date })}`, data);
  },
  UPDATE_KITCHEN(_, data) {
    httpClient.put(`${url}/${data.id}`, data);
  },
  RESET_KITCHENS({ commit }) {
    commit(SET_KITHCENS, []);
  },
  STORE_DELIVERY(_, payload) {
    return httpClient.post(`/delivery`, { orders: payload }).then((resp) => resp.data);
  },
  FETCH_DELIVERY({ commit }, options) {
    httpClient.get(`/delivery${parseQueryParams(options)}`).then((response) => {
      commit('SET_DELIVERY', response.data.data);
    });
  },
  UPDATE_DELIVERY({ dispatch }, payload) {
    httpClient.put(`/delivery`, payload);
    dispatch('FETCH_DELIVERY', { date: payload[0] });
  },
};
