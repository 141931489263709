const semifishedsRoutes = [
  {
    path: 'semifinisheds',
    name: 'Semifinisheds',
    meta: {
      layout: 'main',
      title: 'Полуфабрикаты',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./semifinisheds.vue'),
  },
  {
    path: 'semifinisheds/:id',
    name: 'Semis',
    meta: {
      layout: 'main',
      title: 'Полуфабрикат',
      roles: ['super-admin', 'master-admin'],
      exclude: true,
    },
    component: () => import('./semifished/semifished.vue'),
  },
];

export default semifishedsRoutes;
