import Vue from 'vue';
import Router from 'vue-router';
import { adminRoutes } from './administration';
import { authRoutes } from './auth';
import { clientsRoutes } from './clients';
import courierRoutes from './couriers/courier-routes';
import { dashboardRoutes } from './dashboard';
import { deliveryRoutes } from './delivery';
import { documentsRoutes } from './documents';
import { kitchenRoutes } from './kitchen';
import { managerRoutes } from './manager';
import { settingsRoutes } from './settings';
import { appLocalStorage } from './shared/services';
import { storageRoutes } from './storage';

Vue.use(Router);

const appRoutes = [
  {
    path: '/',
    meta: { exclude: true },
    redirect: '/dashboard',
  },
  {
    path: '*',
    name: 'page-not-found',
    meta: { exclude: true },
    component: () => import('./shared/components/page-not-found/page-not-found.vue'),
  },
];

const routes = [
  ...dashboardRoutes,
  ...clientsRoutes,
  ...managerRoutes,
  ...deliveryRoutes,
  ...kitchenRoutes,
  ...storageRoutes,
  ...authRoutes,
  ...adminRoutes,
  ...settingsRoutes,
  ...appRoutes,
  ...documentsRoutes,
  ...courierRoutes,
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (appLocalStorage.getItem('isLoggedIn')) {
      const isAvalibale = to.matched.some((record) => {
        if (Array.isArray(record.meta.roles)) {
          return record.meta.roles.includes(appLocalStorage.getItem('role'));
        }
        return false;
      });
      if (!isAvalibale) {
        next(false);
      } else {
        next();
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
