import { SUPER_ADMIN, MASTER_ADMIN, ADMINISTRATOR, ADMIN, MANAGER } from '@/constants/roles';
import * as getterTypes from './types/getter_types';

export default {
  [getterTypes.LOADING]: (state) => state.loading,
  [getterTypes.DATA]: (state) => state.data,
  [getterTypes.ERROR]: (state) => state.error,
  [getterTypes.IS_SUPER_ADMIN]: (state) => state.role === SUPER_ADMIN,
  [getterTypes.IS_MASTER_ADMIN]: (state) => state.role === MASTER_ADMIN,
  [getterTypes.IS_ADMINISTRATOR]: (state) => state.role === ADMINISTRATOR,
  [getterTypes.IS_ADMIN]: (state) => state.role === ADMIN,
  [getterTypes.IS_MANAGER]: (state) => state.role === MANAGER,
  [getterTypes.ME]: (state) => state.me,
};
