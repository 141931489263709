import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/manager/shared/config';

const mutationTypes = reflectKeys(
  ['TASKS_SUCCESS', 'TASKS_REQUEST', 'TASKS_ERROR', 'TYPES_SUCCESS', 'SELECT_TASK'],
  namespacedPrefix
);

const { TYPES_SUCCESS, TASKS_SUCCESS, TASKS_REQUEST, TASKS_ERROR, SELECT_TASK } = mutationTypes;

export { TYPES_SUCCESS, TASKS_SUCCESS, TASKS_REQUEST, TASKS_ERROR, SELECT_TASK };
