import {
  MENU_REQUEST,
  MENU_SUCCESS,
} from '@/app/administration/cycles/shared/state/types/mutation_types';
import { deleteClient, fetchClients, fetchTransactions, storeClient } from '../services/clients';
import {
  deleteOrder,
  fetchLonger,
  fetchOrder,
  fetchOrders,
  storeOrders,
  updateOrder,
} from '../services/orders';
import * as actionsTypes from './types/action_types';
import * as mutationTypes from './types/mutation_types';

export default {
  async [actionsTypes.FETCH_ORDERS]({ commit }, options) {
    commit(mutationTypes.ORDERS_REQUEST);
    const result = await fetchOrders(options).catch((e) => {
      commit(mutationTypes.ORDERS_ERROR, e);
    });
    if (result) {
      if (options.mode) {
        commit(mutationTypes.ORDERS_SUCCESS, result);
        return;
      }
      commit(mutationTypes.ORDERS_CAL, result);
    }
    return result;
  },
  async [actionsTypes.FETCH_TRANSACTIONS]({ commit }, payload) {
    commit(mutationTypes.ORDERS_REQUEST);
    const result = await fetchTransactions(payload.id, payload.options).catch((e) => {
      commit(mutationTypes.ORDERS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.FETCH_TRANSACTIONS, result);
    }
    return result;
  },
  async [actionsTypes.FETCH_LONGER]({ commit }, options) {
    const result = await fetchLonger(options).catch((e) => {
      commit(mutationTypes.ORDERS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.LONGER_SUM_SUCCESS, result.data.sum);
    }
    return result;
  },
  async [actionsTypes.STORE_ORDERS]({ commit, dispatch }, payload) {
    commit(mutationTypes.ORDERS_REQUEST);
    await storeOrders(payload).catch((e) => {
      commit(mutationTypes.ORDERS_ERROR, e);
    });
    dispatch(actionsTypes.FETCH_ORDERS, { client: payload.client_id });
  },
  async [actionsTypes.UPDATE_ORDERS]({ commit, dispatch }, payload) {
    commit(mutationTypes.ORDERS_REQUEST);
    await updateOrder(payload, payload.id).catch((e) => {
      commit(mutationTypes.ORDERS_ERROR, e);
    });
    dispatch(actionsTypes.FETCH_ORDERS, { client: payload.client_id });
  },
  async [actionsTypes.FETCH_ORDER]({ commit }, options) {
    commit(MENU_REQUEST);
    const result = await fetchOrder(options);
    if (result) {
      commit(MENU_SUCCESS, result.data);
    }
    return result;
  },
  async [actionsTypes.FETCH_CLIENTS]({ commit }, options) {
    commit(mutationTypes.CLIENTS_REQUEST);
    const result = await fetchClients(options).catch((e) => {
      commit(mutationTypes.CLIENTS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.CLIENTS_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_CLIENT]({ dispatch }, payload) {
    await storeClient(payload).catch(() => {});
    dispatch(actionsTypes.FETCH_CLIENTS);
  },
  async [actionsTypes.DELETE_CLIENT]({ dispatch }, id) {
    await deleteClient(id).catch(() => {});
    dispatch(actionsTypes.FETCH_CLIENTS);
  },
  async [actionsTypes.DELETE_ORDER]({ dispatch }, id) {
    await deleteOrder(id).catch(() => {});
    dispatch(actionsTypes.FETCH_ORDERS, { client: id });
  },
  async [actionsTypes.RESET_CLIENTS]({ commit }) {
    commit(mutationTypes.CLIENTS_RESET);
  },
};
