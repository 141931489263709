<!-- App Page not found component -->
<script>
export default {
  name: 'AppPageNotFound',
};
</script>

<!-- Template -->
<template>
  <h2 class="page-not-found">Page not found</h2>
</template>

<!-- Style -->
<style lang="scss">
.page-not-found {
  text-align: center;
  margin-top: 24px;
  font-weight: 500;
  color: #888888;
}
</style>
