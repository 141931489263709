import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import { deleteBranch, fetchBranches, storeBranch, updateBranch } from '../services/branch';

export default {
  async [actionsTypes.FETCH_BRANCHES]({ commit }, options) {
    commit(mutationTypes.BRANCHES_REQUEST);
    const result = await fetchBranches(options).catch((e) => {
      commit(mutationTypes.BRANCHES_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.BRANCHES_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_BRANCH]({ commit, getters }, payload) {
    commit(mutationTypes.BRANCHES_REQUEST);
    const result = await storeBranch(payload).catch((e) => {
      commit(mutationTypes.BRANCHES_ERROR, e);
    });
    if (result) {
      const branches = getters[getterTypes.DATA];
      branches.data.push(result.data);
      commit(mutationTypes.BRANCHES_SUCCESS, branches);
    }
    return result;
  },
  async [actionsTypes.UPDATE_BRANCH]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.BRANCHES_REQUEST);
    await updateBranch(id, payload).catch((e) => {
      commit(mutationTypes.BRANCHES_ERROR, e);
    });
    commit(mutationTypes.BRANCHES_SUCCESS, getters[getterTypes.DATA]);
  },
  async [actionsTypes.DELETE_BRANCH]({ commit, getters }, id) {
    commit(mutationTypes.BRANCHES_REQUEST);
    const result = await deleteBranch(id).catch((e) => {
      commit(mutationTypes.BRANCHES_ERROR, e);
    });
    if (result) {
      const branches = getters[getterTypes.DATA];
      branches.data = branches.data.filter((branch) => branch.id !== id);
      commit(mutationTypes.BRANCHES_SUCCESS, branches);
    }
    return result;
  },
  [actionsTypes.SELECT_BRANCH]({ commit }, payload) {
    commit(mutationTypes.BRANCHES_SELECT, payload);
  },
  [actionsTypes.SELECT_BRANCH_RESET]({ commit }) {
    commit(mutationTypes.BRANCHES_SELECT_RESET);
  },
};
