import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.EMPLOYEES_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.EMPLOYEES_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.EMPLOYEES_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },
  [mutationTypes.EMPLOYEES_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.EMPLOYEES_SELECT](state, payload) {
    Object.assign(state, { selected: payload });
  },
  [mutationTypes.EMPLOYEES_SELECT_RESET](state) {
    Object.assign(state, {
      selected: {
        name: null,
        email: null,
        password: null,
        role_id: null,
        branch_id: null,
        branch: {
          title: null,
        },
      },
    });
  },
};
