const guideRoutes = [
  {
    path: 'guide',
    name: 'Guide',
    meta: {
      layout: 'main',
      title: 'Справочник блюд',
      roles: ['super-admin', 'master-admin', 'administrator', 'manager'],
    },
    component: () => import('./guide.vue'),
  },
];

export default guideRoutes;
