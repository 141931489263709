import { SET_ORDER, SET_ORDERS } from './mutations_types';

export default {
  [SET_ORDERS](state, orders) {
    state.orders = orders;
  },
  [SET_ORDER](state, order) {
    state.order = order;
  },
};
