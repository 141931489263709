import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchBranches = (options = {}) => {
  const url = `${apiConstants.branches}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const storeBranch = (payload) => {
  const url = apiConstants.branches;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateBranch = (id, payload) => {
  const url = `${apiConstants.branches}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteBranch = (id) => {
  const url = `${apiConstants.branches}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export { fetchBranches, storeBranch, updateBranch, deleteBranch };
