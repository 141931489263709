export const parseQueryParam = (options) => {
  if (!options) return '';
  const keys = Object.keys(options);
  let formattedQueryParams = '';
  keys.forEach((value) => {
    if (value === 'filter') {
      const filterKeys = Object.keys(options[value]);
      filterKeys.forEach((item) => {
        if (options[value][item]) {
          formattedQueryParams += `filter[${item}]=${options[value][item]}&`;
        }
      });
    } else {
      formattedQueryParams += `${value}=${options[value]}&`;
    }
  });
  return `?${formattedQueryParams.substring(0, formattedQueryParams.length - 1)}`;
};
