<template>
  <el-container class="container-height">
    <app-aside />
    <el-container>
      <el-row class="row-width">
        <el-col class="col-height" :span="24">
          <app-header />
        </el-col>
        <el-col :span="24">
          <el-main id="main" class="main">
            <router-view />
          </el-main>
        </el-col>
      </el-row>
    </el-container>
  </el-container>
</template>

<script>
import { AppHeader, AppAside } from '@/app/shared/components';

export default {
  name: 'AppMainLayout',
  components: { AppHeader, AppAside },
};
</script>

<style lang="scss" scoped>
.row-width {
  width: 100%;
}
.col-height {
  height: 60px;
  border-bottom: 3px solid #eee;
}
.main {
  height: 90vh;
  overflow-y: auto;
}
</style>
