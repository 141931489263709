const extraMenuRoutes = [
  {
    path: 'extra-menu',
    name: 'ExtraMenu',
    meta: {
      layout: 'main',
      title: 'Доп. меню',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('@/views/administration/ExtraMenu'),
  },
];

export default extraMenuRoutes;
