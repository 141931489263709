import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';

const url = `/clients`;

export default {
  async FETCH_CLIENTS({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      if (response.status === 204) {
        return false;
      }
      commit('SET_CLIENTS', response.data);
    });
  },
  async FETCH_CLIENT({ commit }, id) {
    await httpClient.get(`${url}/${id}`).then((response) => {
      commit('SET_CLIENT', response.data.data);
    });
  },
  STORE_CLIENT({ commit, getters }, payload) {
    httpClient.post(url, payload).then((response) => {
      const clients = getters.CLIENTS;
      clients.data.unshift(response.data.data);
      commit('SET_CLIENTS', clients);
    });
  },
  async UPDATE_CLIENT({ commit, getters }, payload) {
    let client = getters.CLIENT;
    await httpClient.put(`${url}/${client.id}`, payload).then((response) => {
      const { data } = response.data;
      client = { ...client, ...data };
      commit('SET_CLIENT', client);
    });
  },
  async DELETE_CLIENT({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const clients = getters.CLIENTS;
      clients.data = clients.data.filter((client) => client.id !== id);
      commit('SET_CLIENTS', clients);
    });
  },
  FETCH_CLIENT_MESSAGES({ getters, commit }, options = {}) {
    const { id } = getters.CLIENT;
    httpClient.get(`${url}/${id}/messages${parseQueryParams(options)}`).then((response) => {
      commit('SET_MESSAGES', response.data);
    });
  },
  STORE_CLIENT_MESSAGES({ getters, commit }, data) {
    httpClient.post(`${url}/${getters.CLIENT.id}/messages`, data).then((response) => {
      const messages = getters.MESSAGES;
      messages.data.push(response.data);
      commit('SET_MESSAGES', messages);
    });
  },
  UPDATE_CLIENT_MESSAGES(_, data) {
    data.manager_id = data.manager.id;
    httpClient.put(`${url}/messages/${data.id}`, data);
  },
  DELETE_CLIENT_MESSAGES({ getters }, id) {
    httpClient.delete(`${url}/messages/${id}`).then(() => {
      getters.MESSAGES.data = getters.MESSAGES.data.filter((message) => message.id !== id);
    });
  },
  UPDATE_BALANCE_CLIENT(_, data) {
    return httpClient.post(`${url}/${data.id}/balance`, data).then((res) => res.data.data);
  },
  FETCH_CLIENT_TRANSACTIONS({ commit, getters }, options) {
    const { id } = getters.client;
    httpClient.get(`${url}/${id}/transactions${parseQueryParams(options)}`).then((response) => {
      commit('SET_TRANSACTIONS', response.data);
    });
  },
};
