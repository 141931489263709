import { namespacedPrefix } from '@/app/administration/cycles/shared/config';
import { reflectKeys } from '@/app/shared/services';

const actionsTypes = reflectKeys(
  [
    'FETCH_CYCLES',
    'STORE_CYCLE',
    'UPDATE_CYCLE',
    'DELETE_CYCLE',
    'COPY_CYCLE',
    'SELECT_CYCLE',
    'SELECT_CYCLE_RESET',
    'FETCH_CYCLE_DATA',
    'FETCH_CYCLE_EVENTS',
    'STORE_CYCLE_DATA',
    'UPDATE_CYCLE_DATA',
    'DELETE_CYCLE_DATA',
    'CYCLE_DATA_RESET',
    'FETCH_CYCLE_CURRENT',
    'FETCH_MENU',
    'FETCH_CHILDREN',
    'CYCLE_EVENTS_RESET',
  ],
  namespacedPrefix
);

const {
  FETCH_CYCLES,
  STORE_CYCLE,
  UPDATE_CYCLE,
  DELETE_CYCLE,
  SELECT_CYCLE,
  SELECT_CYCLE_RESET,
  FETCH_CYCLE_DATA,
  STORE_CYCLE_DATA,
  DELETE_CYCLE_DATA,
  UPDATE_CYCLE_DATA,
  FETCH_CYCLE_EVENTS,
  CYCLE_DATA_RESET,
  FETCH_CYCLE_CURRENT,
  FETCH_MENU,
  FETCH_CHILDREN,
  COPY_CYCLE,
  CYCLE_EVENTS_RESET,
} = actionsTypes;

export {
  FETCH_CYCLES,
  STORE_CYCLE,
  UPDATE_CYCLE,
  DELETE_CYCLE,
  SELECT_CYCLE,
  SELECT_CYCLE_RESET,
  FETCH_CYCLE_DATA,
  STORE_CYCLE_DATA,
  DELETE_CYCLE_DATA,
  UPDATE_CYCLE_DATA,
  FETCH_CYCLE_EVENTS,
  CYCLE_DATA_RESET,
  FETCH_CYCLE_CURRENT,
  FETCH_MENU,
  FETCH_CHILDREN,
  COPY_CYCLE,
  CYCLE_EVENTS_RESET,
};
