import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchIngredients = (options = {}) => {
  const url = `${apiConstants.ingredients}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchIngredientCategories = (options) => {
  const url = `${apiConstants.category}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeIngredient = (payload) => {
  const url = apiConstants.ingredients;
  return httpClient.post(url, payload).then((res) => res.data);
};

const updateIngredient = (id, payload) => {
  const url = `${apiConstants.ingredients}/${id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};

const deleteIngredient = (id) => {
  const url = `${apiConstants.ingredients}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

export {
  fetchIngredients,
  storeIngredient,
  updateIngredient,
  deleteIngredient,
  fetchIngredientCategories,
};
