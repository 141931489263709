import { branchesRoutes } from './branches';
import clientTypeRoutes from './client-types/clientTypeRoutes';
import { dishRoutes } from './dishes';
import districtRoutes from './districts/districtRoutes';
import { employesRoutes } from './employes';
import { mealsRoutes } from './meals';
import { unitsRoutes } from './units';

const childRoutes = [
  ...unitsRoutes,
  ...mealsRoutes,
  ...employesRoutes,
  ...dishRoutes,
  ...branchesRoutes,
  ...districtRoutes,
  ...clientTypeRoutes,
];

const settingsRoutes = [
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      layout: 'main',
      auth: true,
      menu: true,
      group: true,
      icon: 'el-icon-s-tools',
      title: 'Настройка системы',
      roles: ['super-admin', 'master-admin', 'administrator', 'admin'],
    },
    component: () => import('./settings.vue'),
    children: childRoutes,
  },
];

export default settingsRoutes;
