import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';
import { SET_MENU } from './mutations_types';

const url = `/menu`;

export default {
  FETCH_MENU({ commit }, options) {
    httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit(SET_MENU, response.data.data);
    });
  },
  RESET_MENU({ commit }) {
    commit(SET_MENU, null);
  },
};
