import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/clients/shared/config';

const gettersTypes = reflectKeys(
  ['LOADING', 'DATA', 'DATA_EXCLUDED', 'ERROR', 'SELECTED', 'LONGER_SUM'],
  namespacedPrefix
);

const { LOADING, DATA, ERROR, SELECTED, LONGER_SUM } = gettersTypes;

export { LOADING, DATA, ERROR, SELECTED, LONGER_SUM };
