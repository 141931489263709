const unitsRoutes = [
  {
    path: 'units',
    name: 'Units',
    meta: {
      layout: 'main',
      title: 'Единицы измерения',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./units.vue'),
  },
];

export default unitsRoutes;
