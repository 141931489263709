import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/auth/shared/config';

const mutationTypes = reflectKeys(
  ['LOGIN_SUCCESS', 'LOGIN_REQUEST', 'LOGIN_ERROR', 'LOGIN_RESET', 'FETCH_ME_SUCCESS', 'SET_ROLE'],
  namespacedPrefix
);

const { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_ERROR, LOGIN_RESET, FETCH_ME_SUCCESS, SET_ROLE } =
  mutationTypes;

export { LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_ERROR, LOGIN_RESET, FETCH_ME_SUCCESS, SET_ROLE };
