import * as mutationTypes from './types/mutation_types';

import initialState from './initialState';

export default {
  [mutationTypes.ORDERS_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.ORDERS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, data: payload });
  },
  [mutationTypes.ORDERS_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: payload || true,
    });
  },
  [mutationTypes.CLIENTS_REQUEST](state) {
    Object.assign(state, { loading: true, error: null });
  },
  [mutationTypes.CLIENTS_SUCCESS](state, payload) {
    Object.assign(state, { loading: false, clients: payload });
  },
  [mutationTypes.CLIENTS_ERROR](state, payload) {
    Object.assign(state, {
      loading: false,
      clients: null,
      error: payload || true,
    });
  },
  [mutationTypes.CLIENT_SELECT](state, payload) {
    Object.assign(state, {
      client: payload,
    });
  },
  [mutationTypes.CLIENT_SELECT_RESET](state) {
    Object.assign(state, {
      client: null,
    });
  },
  [mutationTypes.CLIENTS_RESET](state) {
    Object.assign(state, {
      clients: null,
    });
  },
  [mutationTypes.ORDERS_RESET](state) {
    Object.assign(state, ...initialState);
  },
  [mutationTypes.LONGER_SUM_SUCCESS](state, sum) {
    Object.assign(state, { longerSum: sum });
  },
  [mutationTypes.FETCH_TRANSACTIONS](state, payload) {
    Object.assign(state, { transactions: payload });
  },
  [mutationTypes.ORDERS_CAL](state, payload) {
    Object.assign(state, { orders_cal: payload });
  },
};
