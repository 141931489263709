import { httpClient } from '@/app/shared/services';
import parseQueryParams from '../../../utils/parseQueryParams';

const url = `/meals`;

export default {
  async FETCH_MEALS({ commit }, options) {
    await httpClient.get(`${url}${parseQueryParams(options)}`).then((response) => {
      commit('SET_MEALS', response.data);
    });
  },
  async STORE_MEAL({ getters }, payload) {
    await httpClient.post(url, payload).then((response) => {
      const { data } = response.data;
      const meals = getters.MEALS.data;
      meals.unshift(data);
    });
  },
  async UPDATE_MEAL(_, payload) {
    await httpClient.put(`${url}/${payload.id}`, payload);
  },
  async DELETE_MEAL({ commit, getters }, id) {
    await httpClient.delete(`${url}/${id}`).then(() => {
      const meals = getters.MEALS;
      meals.data = meals.data.filter((meal) => meal.id !== id);
      commit('SET_MEALS', meals);
    });
  },
};
