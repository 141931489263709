import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/clients/shared/config';

const mutationTypes = reflectKeys(
  [
    'ORDERS_SUCCESS',
    'ORDERS_REQUEST',
    'ORDERS_ERROR',
    'ORDERS_RESET',
    'LONGER_SUM_SUCCESS',
    'CLIENTS_SUCCESS',
    'CLIENTS_REQUEST',
    'CLIENTS_ERROR',
    'CLIENT_SELECT',
    'CLIENTS_RESET',
    'CLIENT_SELECT_RESET',
    'FETCH_TRANSACTIONS',
    'ORDERS_CAL',
  ],
  namespacedPrefix
);

const {
  ORDERS_SUCCESS,
  ORDERS_REQUEST,
  ORDERS_ERROR,
  ORDERS_RESET,
  LONGER_SUM_SUCCESS,
  CLIENTS_SUCCESS,
  CLIENTS_REQUEST,
  CLIENTS_ERROR,
  CLIENT_SELECT,
  CLIENTS_RESET,
  CLIENT_SELECT_RESET,
  FETCH_TRANSACTIONS,
  ORDERS_CAL,
} = mutationTypes;

export {
  ORDERS_SUCCESS,
  ORDERS_REQUEST,
  ORDERS_ERROR,
  ORDERS_RESET,
  LONGER_SUM_SUCCESS,
  CLIENTS_SUCCESS,
  CLIENTS_REQUEST,
  CLIENTS_ERROR,
  CLIENT_SELECT,
  CLIENT_SELECT_RESET,
  CLIENTS_RESET,
  FETCH_TRANSACTIONS,
  ORDERS_CAL,
};
