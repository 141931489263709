const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty', exclude: true },
    component: () => import('./login.vue'),
  },
];

export default authRoutes;
