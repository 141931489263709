const catRoutes = [
  {
    path: 'catingredients',
    name: 'Categories_ingredients',
    meta: {
      layout: 'main',
      title: 'Категории ингредиентов',
      roles: ['super-admin', 'master-admin'],
    },
    component: () => import('./category_ingredients.vue'),
  },
];

export default catRoutes;
