<template>
  <el-aside class="aside-height">
    <div class="top-logo">
      <h3 class="title is-3 cursor-pointer" style="color: palevioletred" @click="routeTo">
        #мояеда-edu
      </h3>
    </div>
    <el-menu class="aside-menu-height">
      <el-menu-item
        v-for="(route, index) in isAvalibaleRoutes"
        :key="`single-${index}`"
        :index="route.path"
        @click="handlePageRoute(route.path)"
      >
        <i :class="route.meta.icon" />
        <span>{{ route.meta.title }}</span>
      </el-menu-item>
      <el-submenu v-for="(route, index) in routes" :key="index" :router="true" :index="route.path">
        <template #title>
          <i :class="route.meta.icon" />
          <span>{{ route.meta.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(child, idx) in childrenRoutes(route.children)"
            v-show="!child.meta.exclude"
            :key="idx"
            :index="`${route.path}/${child.path}`"
            @click="handlePageRoute(`${route.path}/${child.path}`)"
          >
            {{ child.meta.title }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppAside',
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
    };
  },
  computed: {
    isAvalibaleRoutes() {
      return this.$router.options.routes.filter((r) => {
        if (!r.meta.exclude) {
          return r.meta.roles.includes(this.role) && r.meta.group !== true;
        }
      });
    },
    routes() {
      return this.$router.options.routes.filter((r) => {
        if (r.meta.menu) {
          return r.meta.roles.includes(this.role);
        }
      });
    },
    ...mapState({
      role: (state) => state.authState.authState.role,
    }),
  },
  methods: {
    handlePageRoute(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    routeTo() {
      this.$router.push('/');
    },
    childrenRoutes(children) {
      return children.filter((r) => r.meta.roles.includes(this.role));
    },
  },
};
</script>

<style lang="scss" scoped>
.top-logo {
  height: 60px;
  border-bottom: 3px solid #eee;
  border-right: 3px solid #eee;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aside-height {
  overflow: hidden;
}
.aside-menu-height {
  height: 90vh;
  overflow-y: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.el-menu {
  border-right: 3px solid #eee;
}
</style>
