import { reflectKeys } from '@/app/shared/services';
import { namespacedPrefix } from '@/app/storage/shared/config';

const mutationTypes = reflectKeys(
  [
    'STORAGE_SUCCESS',
    'STORAGE_REQUEST',
    'STORAGE_ERROR',
    'STORAGE_RESET',
    'STORAGE_SELECT',
    'STORAGE_SELECT_RESET',
  ],
  namespacedPrefix
);

const {
  STORAGE_SUCCESS,
  STORAGE_REQUEST,
  STORAGE_ERROR,
  STORAGE_RESET,
  STORAGE_SELECT,
  STORAGE_SELECT_RESET,
} = mutationTypes;

export {
  STORAGE_SUCCESS,
  STORAGE_REQUEST,
  STORAGE_ERROR,
  STORAGE_RESET,
  STORAGE_SELECT,
  STORAGE_SELECT_RESET,
};
