import * as mutationTypes from './types/mutation_types';
import * as actionsTypes from './types/action_types';
import * as getterTypes from './types/getter_types';

import { deleteUnit, fetchUnits, storeUnit, updateUnit } from '../services/units';

export default {
  async [actionsTypes.FETCH_UNITS]({ commit }, options) {
    commit(mutationTypes.UNITS_REQUEST);
    const result = await fetchUnits(options).catch((e) => {
      commit(mutationTypes.UNITS_ERROR, e);
    });
    if (result) {
      commit(mutationTypes.UNITS_SUCCESS, result);
    }
    return result;
  },
  async [actionsTypes.STORE_UNIT]({ commit, getters }, payload) {
    commit(mutationTypes.UNITS_REQUEST);
    const result = await storeUnit(payload).catch((e) => {
      commit(mutationTypes.UNITS_ERROR, e);
    });
    if (result) {
      const units = getters[getterTypes.DATA];
      units.data.unshift(result.data);
      commit(mutationTypes.UNITS_SUCCESS, units);
    }
    return result;
  },
  async [actionsTypes.UPDATE_UNIT]({ commit, getters }, payload) {
    const { id } = getters[getterTypes.SELECTED];
    commit(mutationTypes.UNITS_REQUEST);
    await updateUnit(id, payload).catch((e) => {
      commit(mutationTypes.UNITS_ERROR, e);
    });
    commit(mutationTypes.UNITS_SUCCESS, getters[getterTypes.DATA]);
  },
  async [actionsTypes.DELETE_UNIT]({ commit, getters }, id) {
    commit(mutationTypes.UNITS_REQUEST);
    const result = await deleteUnit(id).catch((e) => {
      commit(mutationTypes.UNITS_ERROR, e);
    });
    if (result) {
      const units = getters[getterTypes.DATA];
      units.data = units.data.filter((unit) => unit.id !== id);
      commit(mutationTypes.UNITS_SUCCESS, units);
    }
    return result;
  },
  [actionsTypes.SELECT_UNIT]({ commit }, payload) {
    commit(mutationTypes.UNITS_SELECT, payload);
  },
  [actionsTypes.SELECT_UNIT_RESET]({ commit }) {
    commit(mutationTypes.UNITS_SELECT_RESET);
  },
};
