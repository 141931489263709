import { httpClient, parseQueryParam } from '@/app/shared/services';

import { apiConstants } from '../config';

const fetchClients = (options = {}) => {
  const url = `${apiConstants.clients}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeClient = (payload) => {
  const url = apiConstants.clients;
  return httpClient.post(url, payload).then((res) => res.data);
};
const deleteClient = (id) => {
  const url = `${apiConstants.clients}/${id}`;
  return httpClient.delete(url).then((res) => res.data);
};

const fetchLonger = (options = {}) => {
  const url = `${apiConstants.orders}/longer${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const fetchTransactions = (id, options = {}) => {
  const url = `${apiConstants.clients}/${id}/transactions${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

export { fetchClients, storeClient, deleteClient, fetchLonger, fetchTransactions };
