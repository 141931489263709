import axios from 'axios';

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        commit('auth_request');
        await axios
          .post(`${process.env.VUE_APP_API_HOST}/auth/sign-in`, {
            email,
            password,
          })
          .then((response) => {
            const { user } = response.data.data;
            const { token } = response.data.data;
            localStorage.setItem('token', token);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit('auth_success', token, user);
          });
      } catch (e) {
        commit('auth_error');
        localStorage.removeItem('token');
        throw e;
      }
    },
    logout({ commit }) {
      commit('logout');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
    },
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token, user) {
      state.status = 'success';
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
};
