import { namespacedPrefix } from '@/app/administration/guide/shared/config';
import { reflectKeys } from '@/app/shared/services';

const actionsTypes = reflectKeys(
  [
    'FETCH_GUIDES',
    'FETCH_GUIDE',
    'STORE_GUIDE',
    'UPDATE_GUIDE',
    'DELETE_GUIDE',
    'SELECT_GUIDE',
    'SELECT_GUIDE_RESET',
    'CALCULATE_COST_PRICE',
  ],
  namespacedPrefix
);

const {
  FETCH_GUIDE,
  FETCH_GUIDES,
  STORE_GUIDE,
  UPDATE_GUIDE,
  DELETE_GUIDE,
  SELECT_GUIDE,
  SELECT_GUIDE_RESET,
  CALCULATE_COST_PRICE,
} = actionsTypes;

export {
  FETCH_GUIDE,
  FETCH_GUIDES,
  STORE_GUIDE,
  UPDATE_GUIDE,
  DELETE_GUIDE,
  SELECT_GUIDE,
  SELECT_GUIDE_RESET,
  CALCULATE_COST_PRICE,
};
