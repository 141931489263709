import { httpClient, parseQueryParam } from '@/app/shared/services';
import { apiConstants } from '../config';

const fetchOrders = (options = {}) => {
  const url = `${apiConstants.orders}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const storeOrders = (payload) => {
  const url = apiConstants.orders;
  return httpClient.post(url, payload).then((res) => res.data);
};
const updateOrder = (payload) => {
  const url = `${apiConstants.orders}/${payload.id}`;
  return httpClient.put(url, payload).then((res) => res.data);
};
const fetchOrder = (options = {}) => {
  const url = `${apiConstants.order}${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};
const fetchLonger = (options = {}) => {
  const url = `${apiConstants.orders}/longer${parseQueryParam(options)}`;
  return httpClient.get(url).then((res) => res.data);
};

const deleteOrder = (id) => {
  const url = `${apiConstants.orders}/clear`;
  return httpClient.post(url, { client_id: id }).then((res) => res.data);
};

export { fetchOrders, storeOrders, fetchOrder, fetchLonger, updateOrder, deleteOrder };
